const config = require("../config.json");
const baseUrl = config["baseUrl"];
const apiUrl = config["comprehendMedical"];
const fhirAPIUrl = config["fhirApiUrl"];
const env = config["env"];
import axios from 'axios';

const requestUrl = baseUrl + apiUrl + "-" + env;

export function getInteractions(summary, token, accessToken) {

    let interactions = [];
    Object.entries(summary).forEach(s => {
        if(s[1].comprehend && s[1].comprehend.pharma_entities) {
            s[1].comprehend.pharma_entities.forEach(p_e =>  interactions.push(p_e.name));
        }
    });

    let config = {
        method: 'post',
        url: requestUrl + '/db/drug-interactions',
        data: {
            "drugs": interactions,
            "lang": "es"
        },
        headers: {
            "content-type": 'application/json',
            Authorization: token
        }
    };


    if (interactions.length > 0) {
        return axios(config);
    } else {
        return new Promise(() => {
            return {
                data: {
                    interaction_list: []
                }
            }
        });
    }
}

export function analyzeDataComprehendMedical(transcription, token, accessToken) {

    let config = {
        method: 'post',
        url: baseUrl + fhirAPIUrl + "-" + env + "/structure/detect-medical",
        headers: {
            'Content-Type': 'text/plain',
            'Authorization': token,
            'api-token': accessToken
        },
        data : {
            "text": transcription,
            "lang": 'es'
        }
    };

    return axios(config);
}
