import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import CaptureAudioComponent from "./../audio/CaptureAudioComponent";

const useStyles = makeStyles((theme) => ({
  verticalAlign: {
    display: 'flex',
    margin: 'auto'
  },
  verticalAlignBlock: {
    display: 'block',
    margin: 'auto'
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  subtitle: {
    'fontFamily': "Titillium Web",
    'fontSize': "30px",
    'fontStyle': "normal",
    'fontWeight': 200,
    'color': '#021842'
  },
}));


export default function AppointmentFormHeader(props) {
  const classes = useStyles();

  return (
      <React.Fragment>
        <Grid container style={{'padding': 20}}>
          <Grid item xs={'auto'} className={classes.verticalAlignBlock}>
            <Typography className={classes.title} style={{'textAlign': 'center'}}>
              <span style={{'fontWeight': 700}}>{props.stepNumber}</span> <span>{props.stepName}</span>
            </Typography>
            <Typography className={classes.subtitle} style={{'textAlign': 'center'}} gutterBottom>
              {props.subStepName}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
  );
}

AppointmentFormHeader.propTypes = {
  stepName: PropTypes.string,
  subStepName: PropTypes.string,
  stepNumber: PropTypes.string,
};

AppointmentFormHeader.defaultProps = {
  noAudio: false,
};