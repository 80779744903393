import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link, useHistory,} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from "@material-ui/core/Divider";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Icon from "@material-ui/core/Icon";
import {Auth} from 'aws-amplify';
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {login, previousStep} from "../store/actions";
import {useTranslation} from 'react-i18next';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/core/Alert";
import CardContent from "@material-ui/core/CardContent";
import {CodeBracesBox} from "mdi-material-ui";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textField: {
        width: '100%',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    marginDivider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        "border-color": "rgba(255, 255, 255, 0.5)"
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    welcome: {
        marginTop: theme.spacing(10),
        color: 'white'
    },
    margin: {
        margin: theme.spacing(1),
    },
    forgotPassword: {
        marginTop: theme.spacing(3),
        "text-align": "center",
        color: 'white'
    },
    whiteText: {
        color: 'white',
    },
    textFieldWhite: {
        borderWidth: "1px",
        borderColor: "white !important",
    },
    title: {
        'font-size': '45px'
    },
    imageIcon: {
        height: '100%',
        width: '100%'
    },
    iconRoot: {
        marginLeft: theme.spacing(1),
        textAlign: 'center'
    },
    justifyCenterVertical: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
    }
}));

const getUser = state => ({
    user: state.user.user
});

const Login = ({user, dispatch}) => {
    const classes = useStyles();
    let history = useHistory();
    const {t, i18n} = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(true);
    const [errorLogin, setErrorLogin] = React.useState(false);

    if (user) {
        history.push('/patients');
    } else {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            dispatch(login(user));
            history.push('/patients');
        }).catch(err => {
            // console.log(err);
        });
    }

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        username: '',
        weight: '',
        weightRange: '',
        code: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    async function signIn() {
        setLoading(true);
        try {
            const user = await Auth.signIn(values.username, values.password);
            setLoading(false);
            dispatch(login(user));
            history.push('/patients');
        } catch (error) {
            if(error && error.code === 'UserNotConfirmedException') {
                setConfirmed(false);
            } else {
                setErrorLogin(true);
                console.log('error signing in', error);
            }

            setLoading(false);
        }
    }

    async function verify() {
        setLoading(true);
        try {
            await Auth.confirmSignUp(values.username, values.code);
            const user = await Auth.signIn(values.username, values.password);
            setLoading(false);
            dispatch(login(user));
            history.push('/patients');
        } catch (error) {
            setLoading(false);
            console.log('error confirming sign up', error);
        }
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorLogin(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Grid container justifyContent="center" className={classes.justifyCenterVertical}>
                    <Typography component="h1" variant="h5" className={clsx(classes.title, classes.whiteText)}>
                        VOYAGER
                    </Typography>
                    {/*<Typography component="h1" variant="h5" className={clsx(classes.title, classes.whiteText)}>*/}
                    {/*    HUBBLE*/}
                    {/*</Typography>*/}
                    <Icon fontSize="large" classes={{root: classes.iconRoot}}>
                        <img className={classes.imageIcon} src="/logo-unit.svg"/>
                    </Icon>
                </Grid>


                <Typography className={classes.welcome}>
                    {t('welcome')},
                </Typography>
                {confirmed ?
                    <>
                        <Typography className={classes.whiteText}>
                            {t('welcomeSubtitle')}
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                label={t('username')}
                                autoComplete="username"
                                className={clsx(classes.margin, classes.textField)}
                                value={values.username}
                                onChange={handleChange('username')}
                                InputLabelProps={{
                                    style: {color: 'white'},
                                }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.textFieldWhite,
                                        root: classes.whiteText
                                    },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <AccountCircle className={classes.whiteText}/>
                                        </InputAdornment>,
                                }}
                                variant="outlined"
                            />
                            <TextField
                                label={t('password')}
                                autoComplete="current-password"
                                className={clsx(classes.margin, classes.textField, classes.whiteText)}
                                value={values.password}
                                type={values.showPassword ? 'text' : 'password'}
                                onChange={handleChange('password')}
                                InputLabelProps={{
                                    style: {color: 'white'},
                                }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.textFieldWhite,
                                        root: classes.whiteText
                                    },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end">
                                                {values.showPassword ? <Visibility className={classes.whiteText}/> :
                                                    <VisibilityOff className={classes.whiteText}/>}
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                                variant="outlined"
                            />
                            <Grid container direction="column" justify="center" className={classes.forgotPassword}>
                                <Grid item xs>
                                    <Link to="/forgot-password" className={classes.whiteText}>
                                        {t('forgotPassword?')}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Typography href="#" variant="body2" className={classes.whiteText}>
                                        {t('contactUs')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.marginDivider}/>
                            <FormControlLabel control={<Checkbox value="remember" className={classes.whiteText}/>}
                                              className={classes.whiteText}
                                              label={t('rememberMe')}/>
                            {/*<Link style={{ textDecoration: 'none' }} to={`/patients`}>*/}
                            <Button type="button" fullWidth variant="contained" style={{'backgroundColor': '#FA6A09'}}
                                    onClick={signIn}
                                    className={classes.submit}>
                                {!loading ?
                                    <span className={classes.whiteText}>{t('login')}</span> :
                                    <CircularProgress size={25} style={{"color": "white"}}/>}
                            </Button>
                            {/*</Link>*/}
                            <Snackbar open={errorLogin} autoHideDuration={1000}
                                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                      onClose={closeSnackbar}>
                                <Alert onClose={closeSnackbar} color="info" severity="error">
                                    {t('wrongLogin')}
                                </Alert>
                            </Snackbar>
                        </form>
                    </> :
                    <>
                        <Typography className={classes.whiteText}>
                            {t('notConfirmedSubtitle')}
                        </Typography>
                        <form className={classes.form} noValidate>
                        <TextField
                            label={t('code')}
                            className={clsx(classes.margin, classes.textField)}
                            value={values.code}
                            onChange={handleChange('code')}
                            InputLabelProps={{
                                style: {color: 'white'},
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textFieldWhite,
                                    root: classes.whiteText
                                },
                                endAdornment:
                                    <InputAdornment position="end">
                                        <CodeBracesBox className={classes.whiteText}/>
                                    </InputAdornment>,
                            }}
                            variant="outlined"
                        />
                            <Button type="button" fullWidth variant="contained" style={{'backgroundColor': '#FA6A09'}} onClick={verify}
                                    className={classes.submit}>
                                {!loading ?
                                    <span className={classes.whiteText}>{t('verify')}</span> :
                                    <CircularProgress size={25} style={{"color": "white"}}/>}
                            </Button>
                        </form>
                    </>
                }

            </div>
        </Container>
    );
}

export default connect(getUser)(Login)
