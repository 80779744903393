const patientAppointments = (state = {appointments: null}, action) => {
    switch (action.type) {
        case 'UPDATE_PATIENT_APPOINTMENTS':
            return {
                ...state,
                appointments: action.appointments
            };
        case 'ADD_PATIENT_APPOINTMENT':
            return {
                ...state,
                appointments: state.appointments? state.appointments.push(action.appointment): [action.appointment]
            };
        default:
            return state
    }
};

export default patientAppointments;