import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";

export default function Title(props) {
  let history = useHistory();

  return (
    <AppBar position="static" style={{"backgroundColor": "#E0E0E0"}}>
      <Toolbar>
        <Grid container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={useStyles().displayContent}
        >
          {(props.backButton
          ?
          <IconButton size="small" color="primary" className={useStyles().backButton} onClick={() => {history.go(-1)}}>
            <ChevronLeftIcon/>
          </IconButton>
          : null
          )}

          <Grid container item={true} xs={'auto'}>
            <Typography component="h2" variant="h6" className={useStyles().titleStyle} >
              {props.title}
            </Typography>
          </Grid>
          {(props.addButton
              ? <Grid container alignItems="flex-end" className={useStyles().rightAlign} item={true} xs={'auto'}>
                  <Button className={useStyles().button} startIcon={<AddIcon />} onClick={props.addButtonFunc} variant="outlined">
                    <span className={useStyles().spanButton}>{props.addButtonText}</span>
                  </Button>
                </Grid>
              : null
          )}
        </Grid>

      </Toolbar>
    </AppBar>

  );
}

Title.propTypes = {
  title: PropTypes.string,
  addButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  addButtonFunc: PropTypes.func,
  backButton: PropTypes.bool
};

Title.defaultProps = {
  addButton: false,
  backButton: false
};

const useStyles = makeStyles(theme => ({
  button: {
    border: '1px solid rgba(0, 0, 0, 1)',
    color: 'black',
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  rightAlign: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  displayContent: {
    display: 'contents'
  },
  spanButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  "MuiButton-outlinedPrimary:hover": {
    /* border: 1px solid #333333; */
    'background-color': 'rgba(51, 51, 51, 0.04)',
  },
  backButton: {
    'margin-right': 10
  },
  titleStyle: {
    'color': 'black'
  }
}));