const users = (state = {users: null}, action) => {
    switch (action.type) {
        case 'UPDATE_USERS':
            return {
                ...state,
                users: action.users
            };
        default:
            return state
    }
};

export default users;