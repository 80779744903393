const organizations = (state = {organizations: null}, action) => {
    switch (action.type) {
        case 'UPDATE_ORGANIZATIONS':
            return {
                ...state,
                organizations: action.organizations
            };
        default:
            return state
    }
};

export default organizations;