const appointmentStep = (state = { step:  0, appointment: {}, interactions: null}, action) => {
  let appointment = {}
  switch (action.type) {
    case 'NEW_STEP':
      return {
        step: 0,
        appointment: {}
      }
    case 'NEXT_STEP':
      return {
        ...state,
        step: state.step < 7 ? state.step + 1: 7
      }
    case 'PREVIOUS_STEP':
      return {
        ...state,
        step: state.step > 0? state.step - 1: 0
      }
    case 'LOAD_APPOINTMENT':
      let nState = JSON.parse(JSON.stringify(action.appointment));

      Object.keys(nState).forEach(key => nState[key].loaded = true);
      return {
        ...state,
        appointment: nState
      }
    case 'SAVE_PATIENT':

      if(action.step in state.appointment) {
        appointment[action.step] = Object.assign(state.appointment[action.step], action.data)
      } else {
        appointment[action.step] = action.data
      }

      appointment[action.step].step = state.step;
      return {
        ...state,
        appointment: Object.assign(state.appointment, appointment)
      }
    case 'SAVE_AUDIO':
      appointment[action.step] = {
        audioFile: action.audio
      }
      return {
        ...state,
        appointment: Object.assign(state.appointment, appointment)
      }
    case 'SAVE_PHARMACOLOGICAL_INTERACTIONS':
      return {
        ...state,
        interactions: action.interactions
      }
    default:
      return state
  }
}

export default appointmentStep
