import { combineReducers } from 'redux'
import appointmentStep from "./appointmentStep";
import user from "./user";
import patients from "./patients";
import organizations from "./organizations";
import users from "./users";
import practitioners from "./practitioners";
import patientAppointments from "./patientAppointments";
import patientEncounterDetails from "./patientEncounterDetails";
import patientExpertSystem from "./patientExpertSystem";
import patientExams from "./patientExams";
import statisticsPlotData from "./statisticsPlotData";
import patientMedicalRecord from "./patientMedicalRecord";

export default combineReducers(
    {appointmentStep, user, patients,
        organizations, users, practitioners, patientAppointments,
        patientEncounterDetails, patientExpertSystem, patientExams,
        patientMedicalRecord, statisticsPlotData
    })
