import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  AccountSearch, AccountVoice,
  ClipboardPulseOutline,
  FileAlert,
  FileEyeOutline,
  MicrophonePlus,
  FlaskOutline, VirusOutline, Pill
} from 'mdi-material-ui';
import {PlusCircleOutline} from 'mdi-material-ui';
import {Link, useHistory, useParams} from "react-router-dom";
import {connect} from "react-redux";
import NewAppointmentDialog from "../components/forms/NewAppointmentForm";
import {createAppointment, listAppointments, uploadPatientImage} from "../services/fhir-api-services";
import {
  initAppointment,
  updatePatientAppointments,
  updatePatientEncounterDetails, updatePatientExams
} from "../store/actions";
import moment from "moment";
import {Chip, Skeleton, Tooltip} from "@material-ui/core";
import {Badge} from "@material-ui/core";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import {
  loadAppointments,
  loadExpertSystem,
  loadPractitioners
} from "../services/utils/load-data-services";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: 'flex'
  },
  marginTop: {
    marginTop: 15
  },
  displayHidden: {
    display: 'none'
  },
  centerVertically: {
    display: 'flex',
    'align-items': 'center',
    margin: 'auto',
    'justify-content': 'center'
  },
  marginCircular: {
    margin: 10
  },
  paper: {
    padding: '6px 16px',
  },
  marginBottom: {
    marginBottom: 5
  },
  verticalAlign: {
    display: 'flex',
    margin: 'auto'
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  noPadding: {
    padding: 0,
  },
  noRightPadding: {
    'padding-right': 0
  },
  borderRadiusAvatar: {
    "borderRadius": "8px 0px 8px 0px"
  },
  extraLargeAvatar: {
    width: '16vw',
    minWidth: 160,
    minHeight: 100,
    height: '25vh',
  },
  cardClass: {

  },
  spanButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonAddStyle: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  rightAlign: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  paddingTop3: {
    paddingTop: theme.spacing(2)
  },
  badgeProfile: {
    transform: 'scale(1) translate(10%, 0%)',
    height: 'inherit'
  }
}));

const getData = state => ({
  user: state.user.user,
  patients: state.patients.patients,
  practitioners: state.practitioners.practitioners,
  appointments: state.patientAppointments.appointments,
  expertSystem: state.patientExpertSystem.expertSystem
});

const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
    <TextField id="search" type="text" margin="dense" placeholder={filterLabelText}
               aria-label="Search Input" value={filterText} onChange={onFilter}
               InputProps={{
                 startAdornment:
                     <InputAdornment position="start">
                       <AccountSearch />
                     </InputAdornment>,
               }}/>
);

const PatientAppointments = ({props, user, patients,
                               practitioners, appointments,
                               expertSystem, dispatch}) => {
  let history = useHistory();
  const dialogRef = useRef();
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  let { id } = useParams();

  if(!patients) {
    history.push('/patients');
  }

  let cPatient = patients? patients.find(pat => pat.id === id): null;
  const [patient, setPatientValue] = React.useState(cPatient);
  const [filterText, setFilterText] = React.useState('');
  const [profilePhoto, setProfilePhoto] = React.useState((cPatient && cPatient.photo)? cPatient.photo: '/profile.png');
  const [loadingPhoto, setLoadingPhoto] = React.useState(false);
  const [loadingAppointments, setLoadingAppointments] = React.useState(false);

  const handleClickOpen = () => {
    dialogRef.current.openDialog(user, [], patients, practitioners, patient);
  };

  const handleClearFilter = () => {
    if (filterText) {
      setFilterText('');
    }
  };

  const goToExams = () => {
    dispatch(updatePatientExams(null));
    history.push(`/patient-exams/${id}`);
  };

  useEffect(() => {
    if (!appointments && !loadingAppointments) {
      loadAppointments(user, dispatch, id);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }

    if (!expertSystem) {
      loadExpertSystem(id, user, dispatch);
    }

    // loadHGT(id, user, dispatch);
  }, [user])

  const filteredAppointments = appointments? appointments.filter(item =>
      (item.appointmentName && item.appointmentName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.practitioner && item.practitioner.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.appointmentType && item.appointmentType.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.practitionerName && item.practitionerName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.date && item.date.toLowerCase().includes(filterText.toLowerCase()))) : [];

  const goToForm = (appointment) => {
    dispatch(initAppointment());
    history.push(`/appointment-form/${appointment.id}`);
  }

  const goToDetails = (appointment) => {
    dispatch(updatePatientEncounterDetails( null));
    history.push(`/appointment-details/${appointment.id}`);
  }

  const goToMedicalRecord = () => {
    history.push(`/medical-record/${id}`);
  }

  const uploadProfilePhoto = (files) => {
    if(files && files.length > 0) {
      let fr = new FileReader();
      fr.onload = function (e) {
        setLoadingPhoto(true);
        uploadPatientImage(id,
            e.target.result.toString(),
            user.signInUserSession.idToken.jwtToken,
            user.signInUserSession.accessToken.jwtToken).then(response => {
          setProfilePhoto(e.target.result.toString());
          setLoadingPhoto(false);
        }).catch(e => {
          setLoadingPhoto(false);
          console.log(e);
        })
      };
      fr.readAsDataURL(files[0]);
    }
  }

  const addNewAppointment = (a, a_id) => {
    if (appointments) {
      let nAppointments = appointments;
      nAppointments.push(a);
      dispatch(updatePatientAppointments(nAppointments));
      dispatch(initAppointment());
      history.push(`/appointment-form/${a_id}`)
    }
  }

  const getInteractionIcon = (i, count, index) => {
    if(count > 0) {
      switch (i) {
        case "statements":
          return <Tooltip key={index} title={t('patient-appointment-previous-medication')}>
            <Badge badgeContent={count}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                   color="secondary">
              <AccountVoice style={{marginLeft: 10}} color="secondary"/>
            </Badge>
          </Tooltip>;
        case "requests":
          return <Tooltip key={index} title={t('patient-appointment-prescription-drugs')}>
            <Badge badgeContent={count}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                   color="secondary">
              <Pill style={{marginLeft: 10}} color="secondary"/>
            </Badge>
          </Tooltip>;
        case "observations":
          return <Tooltip key={index} title={t('patient-appointment-exams-results')}>
            <Badge badgeContent={count}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                   color="secondary">
              <FileEyeOutline style={{marginLeft: 10}} color="secondary"/>
            </Badge>
          </Tooltip>;
        case "symptoms":
          return <Tooltip key={index} title={t('patient-appointment-new-symptoms')}>
            <Badge badgeContent={count}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                   color="secondary">
              <VirusOutline style={{marginLeft: 10}} color="secondary"/>
            </Badge>
          </Tooltip>;
        case "diagnostics":
          return <Tooltip key={index} title={t('patient-appointment-new-diagnosis')}>
            <Badge badgeContent={count}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                   color="secondary">
              <ClipboardPulseOutline style={{marginLeft: 10}} color="secondary"/>
            </Badge>
          </Tooltip>;
        default:
          return <Tooltip key={index} title={t('patient-appointment-previous-medication')}>
            <Badge badgeContent={count}
                   anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                   color="secondary">
              <FlaskOutline style={{marginLeft: 10}} color="secondary"/>
            </Badge>
          </Tooltip>;
      }
    } else {
      return <></>
    }
  }
  return (
    <React.Fragment>
      <div>
        {/*<Title backButton={true} title="Historial Paciente" addButton={true} addButtonText="Nueva Consulta"*/}
        {/*       addButtonFunc={handleClickOpen}/>*/}
        <Card className={classes.noPadding} elevation={0}>
          <CardContent>
            <Card className={classes.root} variant="outlined" style={{border: 0}}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item className={classes.verticalAlign} >
                    <Badge overlap="rectangular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }} classes={{badge: classes.badgeProfile}}
                           badgeContent={
                             <>
                               {loadingPhoto ?
                                   <CircularProgress style={{color: 'white', padding: '4px', borderRadius: '30px', borderWidth: '', background: 'transparent'}}/> :
                                   <label htmlFor="upload-photo">
                                     <input
                                         style={{display: "none"}}
                                         type="file"
                                         id="upload-photo"
                                         name="upload-photo"
                                         onChange={(e) => uploadProfilePhoto(e.target.files)}
                                     />
                                     <IconButton style={{padding: 0}} component="span">
                                       <PlusCircleOutline fontSize="large"
                                                          style={{
                                                            color: 'white', padding: '4px',
                                                            borderRadius: '8px 0px', background: '#0C2975'
                                                          }}/>
                                     </IconButton>
                                   </label>
                               }
                             </>
                           }>
                      <Avatar className={clsx(classes.extraLargeAvatar, classes.borderRadiusAvatar)}
                              style={{minWidth: "180px", border: '3px solid transparent', background: '#0C2975',}}
                              alt="Patient avatar" src={profilePhoto} />
                    </Badge>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <Typography>
                          <span style={{fontSize: 16}}>{t('patient-appointment-patient-name')}</span>
                        </Typography>
                        <span style={{fontSize: 28}}>
                          {patient ? patient.given + ' ' + patient.family : ''}
                        </span>
                      </Grid>
                      <Divider className={classes.marginBottom}/>
                    </Grid>

                    <Divider style={{background: '#FA6A09', marginBottom: 10, marginTop: 5}}/>

                    <Grid container direction="row">
                      <Grid item xs={12} sm={12}>
                        <Grid container direction="row" className={classes.spaceBetween}>
                          <Grid item xs={6}>
                            <Typography component={'span'}  className={classes.title}>
                              <span style={{color: "#032D60", fontWeight: 400, fontSize: 16}}>
                                {t('patient-appointment-patient-name')}:
                              </span>
                              <br/>
                              <Chip style={{backgroundColor: '#C2E0FF'}} label="Diabetes Tipo 2"/>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component={'span'} className={classes.title}>
                              <span style={{color: "#032D60", fontWeight: 400, fontSize: 16}}>
                                {t('patient-appointment-other-conditions')}:
                              </span>
                              <br/>
                              <Chip style={{backgroundColor: '#C2E0FF'}} label="Transplante de hígado"/>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} className={clsx(classes.spaceBetween, classes.paddingTop3)}>
                        <Grid item xs={6}>
                        <Typography component={'span'} className={classes.title}>
                          <span style={{color: "#032D60", fontWeight: 400, fontSize: 16}}>{t('birth-date')}:</span><br/>
                          <span style={{color: "#032D60", fontWeight: 700, fontSize: 16}}>{patient? moment(patient.bd).format("DD/MM/YYYY"): ''}</span>
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                        <Typography component={'span'}  className={classes.title}>
                          <span style={{color: "#032D60", fontWeight: 400, fontSize: 16}}>{t('identifier')}: </span><br/>
                          <span style={{color: "#032D60", fontWeight: 700, fontSize: 16}}>{patient? patient.identifier: ''}</span>
                        </Typography>
                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container direction="row" justify="space-between" alignItems="center"
                  className={useStyles().displayContent}>
              <Grid container alignItems="flex-start" item={true} xs={6}>
                <Button className={useStyles().button}
                        classes={{endIcon: classes.buttonAddStyle}} style={{"marginRight": 10}} endIcon={<MicrophonePlus/>}
                        onClick={handleClickOpen} variant="contained"
                        color="secondary" disableElevation>
                  <span className={useStyles().spanButton}>{t('new-medical-care')}</span>
                </Button>
                <Button className={useStyles().button}
                        classes={{endIcon: classes.buttonAddStyle}}
                        style={{"marginRight": 10}}
                        endIcon={<FlaskOutline/>}
                        onClick={goToExams} variant="contained"
                        color="secondary" disableElevation>
                  <span className={useStyles().spanButton}>{t('see-exams')}</span>
                </Button>
              </Grid>
              <Grid container item={true} xs={6} className={useStyles().rightAlign}>
                <Button className={useStyles().button}
                        classes={{endIcon: classes.buttonAddStyle}} style={{backgroundColor: '#FA6A09'}} endIcon={<ClipboardPulseOutline style={{color: 'white'}}/>}
                        onClick={goToMedicalRecord} variant="contained"
                        disableElevation>
                  <span className={useStyles().spanButton} style={{color: 'white'}}>{t('look-medical-record')}</span>
                </Button>
              </Grid>
            </Grid>

            <Grid container item={true} xs={12} className={useStyles().rightAlign}>
              <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClearFilter}
                               filterText={filterText} filterLabelText={t('filter-label-text')}/>
            </Grid>
            <Timeline className={classes.noPadding}>
              {filteredAppointments.map((appoint, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent className={classes.displayHidden}>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={{backgroundColor: '#92CEF6'}}>
                    {/*{appoint.icon}*/}
                  </TimelineDot>
                  <TimelineConnector style={{backgroundColor: '#92CEF6', width: 4}}/>
                </TimelineSeparator>
                <TimelineContent className={classes.noRightPadding}>
                  <Card className={classes.cardClass} elevation={0} variant="outlined"
                        style={{backgroundColor: appoint.appointmentName === 'Control'? '#fff': '#F1F8FF'}}>
                    <CardContent>
                      <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                        <Grid item xs={12}>
                          <Grid container direction="column" spacing={3} style={{justifyContent: 'space-between'}}>
                            <Grid item style={{paddingTop: 1 , paddingBottom: 1}}>
                              <Grid container style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item>
                                  <Typography component={'span'}  className={classes.title}>
                                    <b style={{color: '#021842'}}>{appoint.appointmentName}</b>
                                    <span style={{color: "rgba(0, 0, 0, 0.54)"}}> {appoint.date} </span>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography component={'span'}  className={classes.title}>
                                    <b style={{color: '#021842'}}>{appoint.practitioner}</b>
                                    <span style={{color: "rgba(0, 0, 0, 0.54)"}}> {appoint.practitionerName} </span>
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  {appoint.status === 'booked' ?
                                      <IconButton aria-label="delete" onClick={() => goToForm(appoint)}>
                                        <FileAlert/>
                                      </IconButton> :
                                      <IconButton aria-label="delete" onClick={() => goToDetails(appoint)}>
                                        <ChevronRightIcon/>
                                      </IconButton>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{marginRight: 70}}/>
                            <Grid item>
                              <Grid container style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item>
                                  <Typography component={'span'}
                                              style={{color: "#021842"}}>{appoint.appointmentType}</Typography>
                                </Grid>
                                <Grid item>
                                  {Object.keys(appoint.interactions).map((k, index) =>
                                      getInteractionIcon(k, appoint.interactions[k], index))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
              ))}
            </Timeline>
            {!appointments? <Timeline className={classes.noPadding}>
              <TimelineItem>
                <TimelineOppositeContent className={classes.displayHidden}>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <Skeleton animation="wave" width={40} height={40}/>
                  <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent className={classes.noRightPadding}>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>
                          <Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>
                        </Grid>
                        <Grid item xs={5}>
                          <Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>
                          <Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Skeleton animation="wave" width={40} height={40}/>
                        </Grid>
                      </Grid>
                      <Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            </Timeline>: null}
          </CardContent>
        </Card>
        <NewAppointmentDialog ref={dialogRef} addAppointment={(a, a_id) => addNewAppointment(a, a_id)}/>
      </div>
    </React.Fragment>
  );
}

export default connect(getData)(PatientAppointments)