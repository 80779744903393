import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Ghost, Ninja} from 'mdi-material-ui';
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({

}));

export default function NotFound() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    return (
        <Container>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Ninja style={{ fontSize: 100}}/>
                </Grid>
                <Grid item>
                    <Typography component="h1" variant="h5">
                        {t('not-found-description')}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}