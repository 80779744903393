import React, {useRef} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import 'react-h5-audio-player/lib/styles.css';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AppointmentFormHeader from "../../components/appointment/AppointmentFormHeader";
import AppointmentFormStepName from "../../components/appointment/AppointmentFormStepName";
import AppointmentFormStepper from "../../components/appointment/AppointmentFormStepper";
import moment from "moment";
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { alpha } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from "prop-types";
import {createEncounter} from "../../services/fhir-api-services";
import {useHistory} from "react-router-dom";
import {
  nextStep,
  savePatientData, updateExpertSystem,
  updatePatientAppointments,
  updatePharmacologicalInteractions
} from "../../store/actions";
import {getInteractions} from "../../services/structure-data-services";
import {Alert, AlertTitle, InputLabel, Select} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {loadUsers} from "../../services/utils/load-data-services";
import UserForm from "../../components/forms/UserForm";
import ExpertSystemForm from "../../components/forms/ExpertSystemForm";
import {acceptExpertSystemRecommendation, checkExpertSystem} from "../../services/expert-system-services";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {CheckCircle} from "@material-ui/icons";
import {AccountCheck, AccountRemove, MinusCircle} from "mdi-material-ui";
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: '0 !important'
  },
  noBorder: {
    'border': 'none',
    'padding-bottom': 0
  },
  marginTopTextField: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    'justify-content': 'flex-end'
  },
  textAreaTitle: {
    'font-size': 18,
    'font-weight': 500,
    'text-align': 'left'
  },
  resultSubtitle: {
    'margin-top': theme.spacing(1),
    'font-size': 15,
    'font-weight': 500,
    'text-align': 'left'
  },
  resultTitle: {
    'margin-top': theme.spacing(1),
    'font-size': 16 ,
    'font-weight': 500,
    'text-align': 'left'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  buttonClass: {
    'justify-content': 'flex-end',
    'margin-bottom': 'auto',
    'margin-top': 'auto',
    'align-items': 'center',
    'display': 'block'
  },
  justifySpaceBetween: {
    'margin-top': theme.spacing(6),
    'margin-bottom': theme.spacing(3),
    'display': 'flex',
    'justify-content': 'space-between'
  },
  formControl: {
    minWidth: "100%",
  },
}));

const getSummary = state => ({
  user: state.user.user,
  summary: state.appointmentStep.appointment,
  interactions: state.appointmentStep.interactions,
  expertSystem: state.patientExpertSystem.expertSystem,
  practitioners: state.practitioners.practitioners,
  organizations: state.organizations.organizations,
});

const AppointmentSummary = ({ summary, interactions, user, appointment, expertSystem, practitioners, organizations, dispatch }) => {
  var summary = summary
  let history = useHistory();
  const classes = useStyles();
  const dialogRef = useRef();
  const {t, i18n} = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [loadingEncounter, setLoadingEncounter] = React.useState(false);
  const [loadingAcceptRecommendation, setLoadingAcceptRecommendation] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const saveData = () => {
    Object.keys(summary).forEach(key => {
      if(summary[key].comprehend) {
        let comprehend = summary[key].comprehend;

        if(comprehend.condition_entities) {
          comprehend.condition_entities.forEach((c_e, index) => {
            if(!("selected_code" in summary[key].comprehend.condition_entities[index])){
              summary[key].comprehend.condition_entities[index].selected_code = summary[key].comprehend.condition_entities[index].ICD10[0];
            }
          })
        }

        if(comprehend.test_entities) {
          comprehend.test_entities.forEach((t_e, index) => {
            if(!("selected_code" in summary[key].comprehend.test_entities[index])){
              summary[key].comprehend.test_entities[index].selected_code = summary[key].comprehend.test_entities[index].code_list[0];
            }
            if(!("date" in summary[key].comprehend.test_entities[index])){
              summary[key].comprehend.test_entities[index].date = moment(Date()).toISOString()
            }
          })
        }
        
      }
    });

    const org = practitioners.find(p => p.practitioner_id === appointment.practitionerId)
    if(org) {
      const org_id = organizations.find(o => o.name === org.organization);
      if (org_id) {
        setLoadingEncounter(true);
        createEncounter({appointment: appointment.id, organization: org_id.id, conclusion: summary},
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken)
          .then((response) => {
            dispatch(updatePatientAppointments(null));
              history.push(`/appointments/${appointment.patientId}`);
            setLoadingEncounter(false);
          })
          .catch((e) => {
            console.log(e);
            setLoadingEncounter(false);
          });
      }
    }

  };

  if(summary && !interactions) {
    if(user) {
      getInteractions(summary, user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken)
          .then(response => {
            dispatch(updatePharmacologicalInteractions(response.data.interaction_list));
          })
          .catch(e => {
            dispatch(updatePharmacologicalInteractions({}));
            console.log(e);
          });
    }
  }

  let stepsResults = Object.entries(summary).sort((a, b) => a[1].step - b[1].step).map(step => {
    return {
      step: step[1].step + 1,
      stepName: step[1].stepName,
      name: step[0],
      comprehend: step[1].comprehend,
      transcription: step[1].transcription
    }
  });

  const openExpertSystemForm = () => {
    dialogRef.current.openDialog(expertSystem, user, false);
  }

  const checkExpert = (e) => {
    dispatch(updateExpertSystem(e));
  }

  const recommendationHandle = (accept) => {
    setLoadingAcceptRecommendation(true);
    if (accept) {
      acceptExpertSystemRecommendation(
          expertSystem.expert_id,
          expertSystem.pharma,
          user.signInUserSession.idToken.jwtToken).then(response => {
        dispatch(updateExpertSystem(Object.assign({accepted: true}, expertSystem)));
        setLoadingAcceptRecommendation(false);
      }).catch(e => {
        console.log(e);
        setLoadingAcceptRecommendation(false);
      })
    } else {
      dispatch(updateExpertSystem(Object.assign({rejected: true}, expertSystem)));
      setLoadingAcceptRecommendation(false);
    }
  }

  return (
    <React.Fragment>
      <div>
        <Card className={classes.noPadding}>
          <CardContent>
            <Card className={classes.noBorder} variant="outlined">
              <CardContent className={classes.noPadding}>
                <AppointmentFormHeader noAudio={true} stepName={t('summary-step-title')} stepNumber={t('step-4')} subStepName={t('review-and-suggestions')}/>
                <AppointmentFormStepName noAudio={true} activeStep={0} maxSteps={1}
                                         currentStep={7}
                                         maxStepsAllForm={8}/>
                <Grid container className={classes.justifySpaceBetween}>
                  <Grid item xs={6}>
                    <Typography className={classes.textAreaTitle} gutterBottom>
                      {t('summary-step-subtitle')}
                    </Typography>
                  </Grid>


                  <Grid item xs={'auto'} className={classes.buttonClass}>
                    <Button className={classes.marginRight}
                            onClick={() => openExpertSystemForm()} size="small"
                            variant="outlined" color="secondary">
                      {t('summary-step-see-recommendations')}
                    </Button>
                  </Grid>
                </Grid>

                {(expertSystem && expertSystem.recommendation)?

                    <TextField className={classes.marginTopTextField}
                               label={t('recommendation')} value={expertSystem.recommendation}
                               InputProps={{
                                 endAdornment:
                                     <InputAdornment position="end">
                                       {expertSystem.pharma && expertSystem.pharma.length > 0 &&
                                            ((loadingAcceptRecommendation && <CircularProgress size={20} color="secondary" className={clsx(classes.marginRight)}/>) ||
                                                 (expertSystem.accepted && <AccountCheck color="secondary"/>) ||
                                                (expertSystem.rejected && <AccountRemove style={{color: '#FA6A09'}}/>) ||
                                           <>
                                             <IconButton onClick={() => recommendationHandle(true)} edge="end">
                                               <CheckCircle color="secondary"/>
                                             </IconButton>
                                             <IconButton onClick={() => recommendationHandle(false)} edge="end">
                                               <MinusCircle style={{color: '#FA6A09'}}/>
                                             </IconButton>
                                           </>)}
                                     </InputAdornment>
                               }}
                               readOnly fullWidth variant="outlined">
                    </TextField>

                    : null}

                {(interactions && interactions.length > 0) ?
                  <div style={{marginTop: 10}}>
                    {interactions.map((interaction, index) => {
                      return <Alert severity="warning" key={index} variant="filled" color="warning"
                                    style={{marginBottom: 10}}>
                        <AlertTitle>{interaction.ingredient.name}, {interaction.affected_ingredient.name}</AlertTitle>
                        {interaction.description}
                      </Alert>
                    })}
                  </div>
                  : null}

                {stepsResults.map((step, index) => (
                  step.transcription !== ''?
                    <Accordion style={{border: 'none'}} expanded={expanded === ('panel' + step.step)} onChange={handleChange(('panel' + step.step))} key={index}>
                    <AccordionSummary style={{background: '#F1F8FF', marginBottom: '15px', border: '1px solid rgba(0, 0, 0, .125)'}} expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content" id="panel1bh-header">
                      <Typography className={classes.heading}>{t('step') + ' ' + step.step}</Typography>
                      <Typography className={classes.secondaryHeading}>{step.stepName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                      <TextField className={classes.marginTopTextField}
                                 label={t('transcription')} value={step.transcription}
                                 disabled fullWidth multiline rows={4} variant="outlined">
                      </TextField>

                      {(step.comprehend && step.comprehend.condition_entities && step.comprehend.condition_entities.length > 0)?
                        <Typography className={classes.resultSubtitle} gutterBottom>
                          {t('findings')}
                        </Typography>
                        : null}

                      {(step.comprehend && step.comprehend.condition_entities)?
                        step.comprehend.condition_entities.map((value, index) => {
                          return <Grid container spacing={1} key={index}>
                            <Grid item xs={4}>
                              <TextField margin="dense" value={value.finding} onChange={(event) => {}}
                                         label={t('finding')} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField margin="dense"
                                         value={value.presence? t('presence-single'): t('missing')}
                                         onChange={(event) => {}}
                                         label={t('presence')} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField margin="dense"
                                         value={value.attributes.length > 0? value.attributes.map(attr => attr.Type + ": " + attr.Text_es).join(","): "- - - - -"}
                                         onChange={(event) => {}}
                                         label={t('attributes')} fullWidth/>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-code">{t('code')}</InputLabel>
                                <Select native fullWidth label={t('code')}
                                        onChange={(event) => {summary[step.name].comprehend.condition_entities[index].selected_code = summary[step.name].comprehend.condition_entities[index].ICD10.find(i => i.Code === event.target.value);}} inputProps={{name: 'Código', id: 'outlined-code',}}>
                                  {value.ICD10.map(icd10 => <option value={icd10.Code}>{icd10.Description}</option>)}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        }): null}

                      {(step.comprehend && step.comprehend.pharma_entities && step.comprehend.pharma_entities.length > 0)?
                        <Typography className={classes.resultSubtitle} gutterBottom>
                          {t('medicines')}
                        </Typography>
                        : null}

                      {(step.comprehend && step.comprehend.pharma_entities)? step.comprehend.pharma_entities.map((value, index) => {
                        return <Grid container spacing={1} key={index}>
                          <Grid item xs={3}>
                            <TextField margin="dense"
                                       value={value.name}
                                       onChange={(event) => {}}
                                       label={t('name')} fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField margin="dense"
                                       value={value.composition}
                                       onChange={(event) => {}}
                                       label={t('composition')} fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField margin="dense"
                                       value={value.dosage}
                                       onChange={(event) => {}}
                                       label={t('dosage')} fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField margin="dense"
                                       value={value.frequency}
                                       onChange={(event) => {}}
                                       label={t('frequency')} fullWidth/>
                          </Grid>
                        </Grid>
                      }): null}

                      {(step.comprehend && step.comprehend.test_entities && step.comprehend.test_entities.length > 0)?
                          <Typography className={classes.resultSubtitle} gutterBottom>
                            {t('exams')}
                          </Typography> : null}

                      {(step.comprehend && step.comprehend.test_entities)? step.comprehend.test_entities.map((value, index) => {
                        const [examDate, setExamDateValue] = React.useState({
                          date: new Date(),
                        });

                        const handleExamDateValueChange = (props) => (event) => {
                          setExamDateValue({ ...examDate, [props]: event});
                          summary[step.name].comprehend.test_entities[index].date = moment(event).toISOString()
                        };
                        

                        return <Grid container spacing={1} key={index}>
                          <Grid item xs={4}>
                            <TextField margin="dense"
                                       value={value.name}
                                       onChange={(event) => {}}
                                       label={t('name')} fullWidth/>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField margin="dense"
                                       value={value.value}
                                       onChange={(event) => {}}
                                       label={t('value')} fullWidth/>
                          </Grid>
                          <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker margin="dense" id="date-picker-dialog"
                                                  label={t('date')} openTo="date" format="dd/MM/yyyy"
                                                  className={clsx(classes.displayFlex)} value={examDate.date}
                                                  onChange={handleExamDateValueChange("date")}
                                                  KeyboardButtonProps={{'aria-label': 'change date'}}/>
                            </MuiPickersUtilsProvider>
                          </Grid>
                          
                          <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-code">{t('code')}</InputLabel>
                              <Select native fullWidth label={t('code')} 
                                      onChange={(event) => {summary[step.name].comprehend.test_entities[index].selected_code = summary[step.name].comprehend.test_entities[index].code_list.find(code => code.name === event.target.value);}}
                                      inputProps={{name: 'Código', id: 'outlined-code',}}>
                                {value.code_list.map(code => <option value={code.name}>{code.display || code.name}</option>)}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      }): null}

                    </AccordionDetails>
                  </Accordion> : null
                ))}

                <Grid container className={classes.flexEnd}>
                  {!loadingEncounter ?
                      <Button className={classes.marginTopTextField} onClick={() => {
                        saveData()
                        }} variant="contained" color="secondary">
                        {t('finalize')}
                      </Button>:
                      <CircularProgress className={clsx(classes.marginRight, classes.marginTopTextField)}/>
                  }

                </Grid>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
        <ExpertSystemForm ref={dialogRef} checkExpert={(e) => checkExpert(e)}/>
      </div>
    </React.Fragment>
  );
}

AppointmentSummary.propTypes = {
  appointment: PropTypes.object,
};

export default connect(getSummary)(AppointmentSummary)