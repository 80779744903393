const config = require("../config.json");
const baseUrl = config["baseUrl"];
const uploadAudioUrl = config["uploadAudio"];
const transcribeUrl = config["transcribe"];
const comprehendMedicalUrl = config["comprehendMedical"];
const env = config["env"];
import axios from "axios";

export function uploadAudio(data, token, accessToken) {

  let config = {
    method: 'post',
    url: baseUrl + uploadAudioUrl + "-" + env + "/upload-audio",
    headers: {
      'Content-Type': 'text/plain',
      'Authorization': token,
      'api-token': accessToken
    },
    data : {
      "extension": "mp3",
      "file": data
    }
  };

  return axios(config);
}


export function transcribeAudio(id, option, token, accessToken) {

  let config = {
    method: 'post',
    url: baseUrl + transcribeUrl + "-" + env + `/${option}/transcript`,
    data: {
      "audio_key": id + '.mp3',
      "audio_lang": "es-ES"
    },
    headers: {
      'Content-Type': 'text/plain',
      'Authorization': token,
      'api-token': accessToken
    },
  };

  return axios(config);
}
