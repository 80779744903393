import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import {connect} from 'react-redux';
import AppointmentStep from "../../components/appointment/AppointmentStep";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const AppointmentTopic = (props) => {
    const {t, i18n} = useTranslation();

  return (
    <React.Fragment>
      <div>
        <AppointmentStep profession={props.appointment.practitioner}
                         practitionerName={props.appointment.practitionerName}
                         step="appointmentTopic"
                         stepName={t('background')}
                         subStepName={t('appointment-topic')}
                         stepNumber={t('step-1')}
                         maxSteps={3}
                         maxStepsAllForm={8}
                         patientName={props.appointment.patientName}
                         motive={props.appointment.appointmentName}
                         currentStep={1}
                         activeStep={1}/>
      </div>
    </React.Fragment>
  );
}

AppointmentTopic.propTypes = {
    appointment: PropTypes.object,
};

export default connect()(AppointmentTopic)