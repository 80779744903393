import {
    getExamTypes,
    getExam, getPatientMedicalRecord,
    listAppointments,
    listOrganizations,
    listPatients,
    listPractitioners,
    listUsersFromApi,
    getStatisticsData
} from "../fhir-api-services";
import {
    updateExamTypes,
    updateExpertSystem,
    updateOrganizations,
    updatePatientAppointments, updatePatientExams, updatePatientMedicalRecord,
    updatePatients, updatePractitioners,
    updateUsers,
    updateStatisticsData
} from "../../store/actions";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import React from "react";
import {createExpertSystem} from "../expert-system-services";

export function loadOrganizations(user, dispatch) {
    let organizations = [];
    if (user) {
        listOrganizations(user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            response.data.forEach(org => {
                let organization = Object.assign(org,
                    {
                        addressN: org.address && org.address[0].line? org.address[0].line[0]: '',
                        district: org.address? org.address[0].district: '',
                        country: org.address? org.address[0].country: '',
                        code: org.identifier? org.identifier[0]: '',
                        phone: org.telecom? org.telecom[0].value : ''
                    });
                organizations.push(organization);
            });
            organizations = organizations.filter((org, index, self) => index === self.findIndex((t) => (
                t.name === org.name
            )))
            dispatch(updateOrganizations(organizations));
        }).catch((e) => {
            console.log(e);
        });
    }
}

export function loadPatients(user, dispatch) {
    let patients = [];
    if (user) {
        listPatients(user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            response.data.forEach(pat => {
                let patient = Object.assign(pat, {name: pat.given + " " + pat.family,
                    lastAppointment: new Date().toISOString().substr(0,10), bd: pat.birthDate,
                    nextAppointment: new Date().toISOString().substr(0,10)});
                patient.birthDate = new Date(patient.birthDate);
                patients.push(patient);
            });
            dispatch(updatePatients(patients));
        }).catch((e) => {
            console.log(e);
        });
    }
}

export function loadUsers(user, dispatch) {
    let users = []
    if (user) {
        listUsersFromApi(user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            response.data.forEach(usr => {
                let user = Object.assign(usr, {});
                users.push(user);
            });
            dispatch(updateUsers(users));
        }).catch((e) => {
            console.log(e);
        });
    }
}

export function loadAppointments(user, dispatch, id) {
    if(user) {
        listAppointments(id, user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            dispatch(updatePatientAppointments( response.data.map((appoint, index) => {
                let practitioner = appoint.practitioner;
                let patient = appoint.patient;
                let interactions = {};

                if(appoint.extension && appoint.extension.count) {
                    interactions = appoint.extension.count;
                }

                return {
                    id: appoint.id,
                    icon: <EventIcon />,
                    appointmentObj: appoint.appointmentObj,
                    appointmentName: appoint.appointmentType.name? appoint.appointmentType.name : "Control",
                    appointmentType: "Control de diabetes",
                    date: moment(appoint.start).format('DD/MM/YYYY'),
                    created: appoint.created? moment(appoint.created).format('DD/MM/YYYY'): null,
                    status: appoint.status,
                    patientObj: patient,
                    patientId: patient.id,
                    patientName: patient.name,
                    practitioner: appoint.specialty.name? appoint.specialty.name : "Enfermería",
                    practitionerName: practitioner.name,
                    practitionerObj: practitioner,
                    practitionerId: practitioner.id,
                    interactions: interactions
                };
            })));
        }).catch((e) => {
            dispatch(updatePatientAppointments( []));
            console.log(e);
        });
    }
}

export function loadExpertSystem(id, user, dispatch) {
    let users = []
    if (user) {
        createExpertSystem(id, user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            dispatch(updateExpertSystem(response.data));
        }).catch((e) => {
            console.log(e);
            dispatch(updateExpertSystem({}));
        });
    }
}

export function loadPractitioners(user, dispatch) {
    let practitioners = []
    if (user) {
        listPractitioners(user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            response.data.forEach(prt => {
                let practitioner = Object.assign(prt, {name: prt.name || ((prt.given && prt.family)? (prt.given + " " + prt.family): null) || prt.username});
                practitioners.push(practitioner);
            });
            dispatch(updatePractitioners(practitioners));
        }).catch((e) => {
            console.log(e);
            dispatch(updatePractitioners(practitioners));
        });
    }
}

export function loadExam(id, code, user, dispatch) {
    let exams = []
    if (user) {
        getExam(id, code, user.signInUserSession.idToken.jwtToken,
            user.signInUserSession.accessToken.jwtToken).then((response) => {
            response.data.exam_data.forEach(exam => {
                exams.push({
                    date: moment.parseZone(exam.date).format('DD/MM/YYYY HH:mm:ss'),
                    unit: exam.unit,
                    value: exam.value
                })
            })
            dispatch(updatePatientExams(exams));
        }).catch((e) => {
            console.log(e);
            dispatch(updatePatientExams(exams));
        });
    }
}

export function loadExamTypes(user, dispatch) {
    let exams = []
    if (user) {
        getExamTypes(user.signInUserSession.idToken.jwtToken,
            user.signInUserSession.accessToken.jwtToken).then((response) => {
                Object.keys(response.data.exam_codes).forEach(k => {
                    exams.push(
                        {
                            name: response.data.exam_codes[k].display,
                            code: response.data.exam_codes[k].code,
                            system: response.data.exam_codes[k].system,
                            value: response.data.exam_codes[k].code,
                        }
                    )
                })
            dispatch(updateExamTypes(exams));
        }).catch((e) => {
            console.log(e);
            dispatch(updateExamTypes(exams));
        });
    }
}

export function loadPatientMedicalRecord(id, user, dispatch) {
    var medicalRecord = {};
    if (user) {
        getPatientMedicalRecord(id, user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
            medicalRecord.data = response.data
            medicalRecord.id = id    
            dispatch(updatePatientMedicalRecord(medicalRecord));
        }).catch((e) => {
            console.log(e);
            dispatch(updatePatientMedicalRecord({}));
        });
    }
}

export function loadStatisticsData(user, dispatch) {
    if (user) {
        getStatisticsData(
            user.signInUserSession.idToken.jwtToken,
            user.signInUserSession.accessToken.jwtToken).then((response) => {
            dispatch(updateStatisticsData(response.data));
        }).catch((e) => {
            console.log(e);
            dispatch(updateStatisticsData({}));
        });
    }
}