import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import PauseIcon from "@material-ui/icons/Pause";
import MicIcon from "@material-ui/icons/Mic";
import PropTypes from "prop-types";
import {saveAudioStep} from "../../store/actions";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {TextToSpeech} from "mdi-material-ui";
import {useTranslation} from "react-i18next";

const MicRecorder = require('mic-recorder-to-mp3');
const sdk = require("microsoft-cognitiveservices-speech-sdk");
const config = require("../../config.json");
const subscriptionToken = config["cognitiveServicesSubscriptionToken"];
const region = config["cognitiveServicesRegion"];

const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionToken, region);
speechConfig.speechRecognitionLanguage = "es-ES";
let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
let recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  fabProgress: {
    position: 'absolute',
    zIndex: 1,
    color: '#0999FA'
  },
}));

const CaptureAudioComponent = ({ dispatch, step, transcribing, uploadAudio, updateStreamingText, transcribeOption }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const {t, i18n} = useTranslation();

  // New instance
  const recorder = useMemo( () =>  new MicRecorder({
    bitRate: 128,
    sampleRate: 16000
  }), []);

  const startStreaming = () => {
    setLoading(!loading);

    // console.log('Speak into your microphone.');
    recognizer.recognizing = (s, e) => {
      // console.log(`RECOGNIZING: Text=${e.result.text}`);
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
        if(e.result.text !== '') {
          updateStreamingText(e.result.text);
        }
        // console.log(`RECOGNIZED: Text=${e.result.text}`);
      }
      else if (e.result.reason === sdk.ResultReason.NoMatch) {
        // console.log("NOMATCH: Speech could not be recognized.");
      }
    };

    recognizer.canceled = (s, e) => {
      // console.log(`CANCELED: Reason=${e.reason}`);

      if (e.reason === sdk.CancellationReason.Error) {
        // console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
        // console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
        // console.log("CANCELED: Did you update the subscription info?");
      }

      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.sessionStopped = (s, e) => {
      // console.log("\n    Session stopped event.");
      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.startContinuousRecognitionAsync();
  }

  const stopStreaming = () => {
    setLoading(!loading);
    recognizer.stopContinuousRecognitionAsync();
  }


  return (
    <React.Fragment>
      <div>
        <Grid container direction="column" className={classes.wrapper}>
          <Grid item style={{'marginBottom': 5}}>
            <IconButton className={classes.margin} onClick={(e) => !loading? startStreaming(): stopStreaming()}>
              {loading ? <PauseIcon color="secondary" fontSize="large"/> :
                  (transcribing? <TextToSpeech color="secondary" fontSize="large"/> :
                      <MicIcon color="secondary" fontSize="large"/>)}
              {(loading || transcribing) && <CircularProgress size={68} className={classes.fabProgress}/>}
            </IconButton>

          </Grid>
          <Grid item>
            {!loading ?
                (!transcribing?
                        <span style={{'fontSize': 15, 'fontWeight': 700, 'fontFamily': 'Mulish'}}>{t('record')}</span> :
                        <span style={{'fontSize': 15, 'fontWeight': 700, 'fontFamily': 'Mulish'}}>{t('transcribing')}</span>
                ) :
                <span style={{'fontSize': 15, 'fontWeight': 700, 'fontFamily': 'Mulish'}}>{t('transcribe')}</span>
            }
          </Grid>
        </Grid>

      </div>
    </React.Fragment>
  );
}

CaptureAudioComponent.propTypes = {
  step: PropTypes.string,
  transcribing: PropTypes.bool,
  transcribeOption: PropTypes.string,
  uploadAudio: PropTypes.func,
  updateStreamingText: PropTypes.func,
};

export default connect()(CaptureAudioComponent)