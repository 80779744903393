export const nextStep = () => ({
  type: 'NEXT_STEP'
});

export const previousStep = () => ({
  type: 'PREVIOUS_STEP'
});

export const initAppointment = () => ({
  type: 'NEW_STEP'
});

export const loadAppointmentFromJSON = (data) => ({
  type: 'LOAD_APPOINTMENT',
  appointment: data
});

export const addPatientAppointment = (appointment) => ({
  type: 'ADD_PATIENT_APPOINTMENT',
  appointment: appointment
});

export const savePatientData = (data) => ({
  type: 'SAVE_PATIENT',
  data: data.appointment,
  step: data.step,
});

export const saveAudioStep = (data) => ({
  type: 'SAVE_AUDIO',
  audio: data.audio,
  step: data.step,
});

export const login = (user) => ({
  type: 'LOGIN',
  user: user,
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const updatePatients = (patients) => ({
  type: 'UPDATE_PATIENTS',
  patients: patients
});

export const updateOrganizations = (organizations) => ({
  type: 'UPDATE_ORGANIZATIONS',
  organizations: organizations
});

export const updateUsers = (users) => ({
  type: 'UPDATE_USERS',
  users: users
});

export const updatePractitioners = (practitioners) => ({
  type: 'UPDATE_PRACTITIONERS',
  practitioners: practitioners
});

export const updatePatientAppointments = (appointments) => ({
  type: 'UPDATE_PATIENT_APPOINTMENTS',
  appointments: appointments
});

export const updatePatientEncounterDetails = (appointment) => ({
  type: 'UPDATE_PATIENT_ENCOUNTER_DETAILS',
  appointment: appointment
});

export const updatePharmacologicalInteractions = (interactions) => ({
  type: 'SAVE_PHARMACOLOGICAL_INTERACTIONS',
  interactions: interactions
});

export const updateExpertSystem = (expertSystem) => ({
  type: 'UPDATE_EXPERT_SYSTEM',
  expertSystem: expertSystem
});

export const updatePatientExams = (exams) => ({
  type: 'UPDATE_PATIENT_EXAMS',
  exams: exams
});

export const updateExamTypes = (types) => ({
  type: 'UPDATE_EXAM_TYPES',
  types: types
});

export const updatePatientMedicalRecord = (medicalRecord) => ({
  type: 'UPDATE_MEDICAL_RECORD',
  medicalRecord: medicalRecord
});

export const updateStatisticsData = (data) => ({
  type: 'UPDATE_STATISTICS_DATA',
  data: data
});