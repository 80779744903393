import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import 'react-h5-audio-player/lib/styles.css';
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  AccountMultiple,
  AccountPlus,
  AccountSearch,
  MicrophonePlus,
  PencilOutline,
  TextBoxMultipleOutline
} from 'mdi-material-ui';
import PatientDialog from "../components/forms/PatientDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import DataTable from 'react-data-table-component';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {initAppointment, updateExpertSystem, updatePatientAppointments, updatePractitioners} from "../store/actions";
import Typography from "@material-ui/core/Typography";
import NewAppointmentDialog from "../components/forms/NewAppointmentForm";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {loadOrganizations, loadPatients, loadPractitioners, loadUsers, loadStatisticsData, loadPatientMedicalRecord} from "../services/utils/load-data-services";
import Avatar from "@material-ui/core/Avatar";
import {useTranslation} from "react-i18next";
import * as moment from 'moment';
import 'moment/locale/es';
import Skeleton from "@material-ui/core/Skeleton";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: 'flex'
  },
  marginTop: {
    marginTop: 15
  },
  displayHidden: {
    display: 'none'
  },
  centerVertically: {
    display: 'flex',
    'align-items': 'center',
    margin: 'auto',
    'justify-content': 'center',
    'flex-wrap': 'nowrap'
  },
  centerOnlyVertically: {
    display: 'flex',
    margin: 'auto',
    'align-items': 'center',
    'flex-wrap': 'nowrap'
  },
  marginCircular: {
    margin: 10
  },

  verticalAlignBlock: {
    display: 'block',
    margin: 'auto',
    'padding': 10
  },
  button: {
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonOtherColor: {
    borderColor: '#346DFF',
    backgroundColor: '#E6ECF2',
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  rightAlign: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  displayContent: {
    display: 'contents'
  },
  spanButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  "MuiButton-outlinedPrimary:hover": {
    /* border: 1px solid #333333; */
    'background-color': 'rgba(51, 51, 51, 0.04)',
  },
  backButton: {
    'padding-left': 0,
    'padding-right': 0,
    'margin-right': 10
  },
  titleStyle: {
    'color': 'black'
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  spaceBetween: {
    display: 'flex',
    'justifyContent': 'space-between'
  },
  buttonAddStyle: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  }
}));

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#e0e0e0',
  },
}))(LinearProgress);

const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
    <TextField id="search" type="text" margin="dense" placeholder={filterLabelText}
               aria-label="Search Input" value={filterText} onChange={onFilter}
               InputProps={{
                 startAdornment:
                     <InputAdornment position="start">
                       <AccountSearch />
                     </InputAdornment>,
               }}/>
);

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#F1F8FF',
      borderBottom: "2px solid",
      borderColor: "#FA6A09",
    },
  },
};

const getData = state => ({
  user: state.user.user,
  users: state.users.users,
  patients: state.patients.patients,
  organizations: state.organizations.organizations,
  practitioners: state.practitioners.practitioners,
  statisticsPlotData: state.statisticsPlotData.data
});

const Patients = ({user, users, patients, organizations, practitioners, statisticsPlotData, medicalRecord, dispatch}) => {
  const classes = useStyles();
  let history = useHistory();
  const dialogRef = useRef();
  const appointmentRef = useRef();
  const {t, i18n} = useTranslation();

  const [filterText, setFilterText] = React.useState('');
  const [styles, setStyles] = React.useState({
    buttonPrimaryStyle: useStyles().button,
    buttonPrimarySpanStyle: useStyles().spanButton,
    buttonSecondaryStyle: useStyles().buttonOtherColor,
    buttonSecondarySpanStyle: useStyles().spanButton,
  });

  useEffect(() => {
    if(!users) {
      loadUsers(user, dispatch);
    }

    if (!patients) {
      loadPatients(user, dispatch);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }

    if (!organizations) {
      loadOrganizations(user, dispatch);
    }

    // const organizationId = organizations.filter(function (item) {
    //   const arr = item.name === user.attributes["custom:organization"]
    //   return arr[0].id
    // })

    if(!statisticsPlotData) {
      loadStatisticsData(user, dispatch);
    }

  }, [user]);

  const openNewAppointment = () => {
    appointmentRef.current.openDialog(user, organizations, patients, practitioners);
  };

  const handleClickOpen = (pat, isEdit) => {
      dialogRef.current.openDialog(pat, user, isEdit, organizations);
  };

    const handleClear = () => {
        if (filterText) {
            setFilterText('');
        }
    };
  const addNewAppointment = (a, a_id) => {
    let nAppointments = [];
    nAppointments.push(a);
    dispatch(updatePatientAppointments(nAppointments));
    dispatch(initAppointment());
    history.push(`/appointment-form/${a_id}`)
  }


  useEffect(() => {
    if (patients) {
      if(!medicalRecord) {
          loadPatientMedicalRecord(patients["0"]["id"], user, dispatch);
      }
    }
  }, [medicalRecord]);

  const subHeaderComponentMemo = <Grid container direction="row" justify="space-between" alignItems="center"
                                       className={useStyles().displayContent}>
    <Grid container alignItems="center" item={true} xs={6}>
      {(!organizations || !patients || !user) && <><Skeleton width={141} height={59} style={{"marginRight": 10}}/><Skeleton width={141} height={59} /></>}
      {(organizations && patients && user) &&
      <>
        <Button className={styles.buttonPrimaryStyle}
                classes={{endIcon: classes.buttonAddStyle}}
                style={{"marginRight": 10}}
                endIcon={<MicrophonePlus/>}
                onClick={() => openNewAppointment()} variant="contained"
                color="secondary" disableElevation>
          <span className={styles.buttonPrimarySpanStyle}>
            {t('new-attention')}
          </span>
        </Button>
        <Button classes={{endIcon: classes.buttonAddStyle}} className={styles.buttonSecondaryStyle} disableElevation variant="outlined"
              endIcon={<AccountPlus style={{color: '#346DFF'}}/>} onClick={() => handleClickOpen()}>
        <span className={styles.buttonSecondarySpanStyle} style={{color: '#346DFF'}}>
          {t('new-patient')}
        </span>
      </Button>
      </>
      }
    </Grid>
    <Grid container item={true} xs={6} className={useStyles().rightAlign}>
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText}
                       filterLabelText={t('filter-label-text')}/>
    </Grid>

  </Grid>;

  const goToPatientDetails = (id) => {
    dispatch(updatePatientAppointments(null));
    dispatch(updateExpertSystem(null));
    history.push(`/appointments/${id}`);
  }

  const columns = [
    {
      name: t('patient'),
      selector: 'name',
      cell: (a) => <Grid container className={classes.centerOnlyVertically}>
        <Avatar alt="Patient avatar" src={a.photo} className={classes.small}  />
        <span style={{"paddingLeft": 5}}>{a.name}</span>
      </Grid>,
      sortable: true,
    },
    {
      name: t('patients-rut-or-passport'),
      selector: 'identifier',
      sortable: true,
      right: true,
      hide: 'sm'
    },
    {
      name: t('last-appointment'),
      selector: 'lastAppointment',
      sortable: true,
      right: true,
      hide: 'sm'
    },
    {
      name: t('next-appointment'),
      selector: 'nextAppointment',
      sortable: true,
      right: true,
      hide: 'sm'
    },
    {
      name: t('actions'),
      cell: (a) => <Grid container className={classes.centerVertically}>
        <IconButton size="small" style={{backgroundColor: '#E6ECF2'}} onClick={() => handleClickOpen(a, true)}>
          <PencilOutline color="secondary"/>
        </IconButton>
        <IconButton size="small" style={{backgroundColor: '#346DFF', marginLeft: 5}} color="secondary" onClick={() => goToPatientDetails(a.id)}>
          <TextBoxMultipleOutline style={{color: 'white', padding: 2}}/>
        </IconButton>
      </Grid>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: false
    },
  ];

  const filteredItems = patients? patients.filter(item =>
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") && item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterText.toLowerCase())) ||
    (item.identifier && item.identifier.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.identifier.replaceAll(".","") && item.identifier.replaceAll(".","").toLowerCase().includes(filterText.toLowerCase())) ||
    (item.lastAppointment && item.lastAppointment.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.nextAppointment && item.nextAppointment.toLowerCase().includes(filterText.toLowerCase()))) : [];

  const currentUser = user && users? users.find(u => u.username === user.username): null;

  return (
    <React.Fragment>
      <div>
        <Grid item xs={'auto'} className={classes.verticalAlignBlock}>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={'auto'}>
              <Typography>
                {t('user-area')}: {currentUser ? t(currentUser.practitioner) : t('user-not-defined')}
              </Typography>
            </Grid>
            <Grid item xs={'auto'}>
              <Typography>
                {t('user-medical-center')}: {currentUser ? (currentUser.organization) : t('user-not-defined')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={'auto'}>
              <Typography variant="h5" gutterBottom>
                <b>{currentUser ? (currentUser.name || currentUser.username) : t('user-no-name')}</b>
              </Typography>
            </Grid>
            <Grid item xs={'auto'}>
              <Typography variant="h7" gutterBottom>
                {moment().locale('es').format('llll')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <DataTable
            noHeader
            subHeader
            striped
            persistTableHead
            responsive
            pagination
            paginationComponentOptions={
              {
                rowsPerPageText: t('elements-per-page'),
                rangeSeparatorText: t('range-separator-text'),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: t('select-all-rows')
              }
            }
            noDataComponent={t('no-patients-registered')}
            subHeaderComponent={subHeaderComponentMemo}
            subHeaderAlign="left"
            columns={columns}
            customStyles={customStyles}
            data={filteredItems}
        />
        {!patients? <BorderLinearProgress/> : null}
        <PatientDialog ref={dialogRef} reload={() => loadPatients(user, dispatch)}/>
        <NewAppointmentDialog ref={appointmentRef} addAppointment={(a, a_id) => addNewAppointment(a, a_id)}/>
      </div>
    </React.Fragment>
  );
}

export default connect(getData)(Patients)
