const patientMedicalRecord = (state = {medicalRecord: null}, action) => {
    switch (action.type) {
        case 'UPDATE_MEDICAL_RECORD':
            return {
                ...state,
                medicalRecord: action.medicalRecord
            };
        default:
            return state
    }
};

export default patientMedicalRecord;