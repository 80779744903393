import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import 'react-h5-audio-player/lib/styles.css';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AppointmentFormHeader from "../../components/appointment/AppointmentFormHeader";
import AppointmentFormStepName from "../../components/appointment/AppointmentFormStepName";
import AppointmentFormStepper from "../../components/appointment/AppointmentFormStepper";
import {nextStep, savePatientData} from '../../store/actions';
import {connect} from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/core/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {transcribeAudio, uploadAudio} from "../../services/transcribe-services";
import {analyzeDataComprehendMedical} from "../../services/structure-data-services";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  noPadding: {
    padding: '0 !important'
  },
  noBorder: {
    'border': 'none',
    'padding-bottom': 0
  },
  marginTopTextField: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  flexStart: {
    display: 'flex',
    'justify-content': 'flex-start'
  },
  marginRight: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonOtherColor: {
    backgroundColor: '#E6ECF2',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function getSteps() {
  return [{id: 1, label: ''}, {id: 2, label: ''}, {id: 3, label: ''}, {id: 4, label: ''}, {id: 5, label: ''}, {id: 6, label: ''}, {id: 7, label: ''}, {id: 8, label: ''}];
}

const getSummary = state => ({
  user: state.user.user,
  summary: state.appointmentStep.appointment
})

const AppointmentStep = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const {t, i18n} = useTranslation();
  const [missingAudio, setMissingAudio] = React.useState(false);
  const [transcription, setTranscription] = React.useState(((props.step in props.summary) && props.summary[props.step].transcription)? props.summary[props.step].transcription : '');
  const [comprehend, setComprehend] = React.useState(((props.step in props.summary) && props.summary[props.step].comprehend)? {data: props.summary[props.step].comprehend} : {});
  const [changed, setChanged] = React.useState(false);
  const [transcribeOption, setTranscribeOption] = React.useState('Streaming');
  const [uploading, setUploading] = React.useState(false);
  const [loadingComprehend, setLoadingComprehend] = React.useState(false);
  const [transcriptionId, setTranscriptionId] = React.useState(((props.step in props.summary) && props.summary[props.step].transcriptionId)? props.summary[props.step].transcriptionId : '');

  const currentTranscription = ((props.step in props.summary) && props.summary[props.step].transcription)? props.summary[props.step].transcription : '';

  if(transcription === '' && currentTranscription !== transcription) {
    setTranscription(currentTranscription)
  }

  const refTranscription = React.useRef(transcription);

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMissingAudio(false);
  };

  const handleTextChange = (event) => {
    setChanged(true);
    setTranscription(event.target.value);
  };

  const uploadAudioHandler = () => {

    if(!props.summary || !(props.step in props.summary) || !props.summary[props.step].audioFile) {
      setMissingAudio(true);
    } else {
      setUploading(true);
      setTranscriptionId(null);

      let reader = new FileReader();
      reader.readAsDataURL(props.summary[props.step].audioFile);
      reader.onloadend = function () {

        uploadAudio(reader.result.replace("data:audio/mp3;base64,", ""),
          props.user.signInUserSession.idToken.jwtToken,
          props.user.signInUserSession.accessToken.jwtToken)
          .then((response) => {
            let id = response.data.audio_id;

            transcribeAudio(id, transcribeOption, props.user.signInUserSession.idToken.jwtToken, props.user.signInUserSession.accessToken.jwtToken)
              .then((response) => {
              // console.log(response);
              setUploading(false);
              setTranscription(transcription? (transcription + '\n' + response.data.transcript): response.data.transcript);
              setTranscriptionId(response.data.transcription_id);
            })
              .catch((e) => {
                setUploading(false);
                console.log(e);
              });
          })
          .catch((e) => {
            setUploading(false);
            console.log(e);
          });
      }
    }
  }

  const updateStreamingTextHandler = (text) => {

    let transcription = refTranscription.current;
    setTranscription(transcription? (transcription + '\n' + text): text);
    refTranscription.current = transcription? (transcription + '\n' + text): text;
    setChanged(true);
  }

  const sendComprehendMedical = () => {

    setLoadingComprehend(true);
    setComprehend({});

    return analyzeDataComprehendMedical(transcription, props.user.signInUserSession.idToken.jwtToken, props.user.signInUserSession.accessToken.jwtToken);
  }

  const cleanAll = () => {
    setTranscription(null);
    refTranscription.current = ""
    setComprehend({});
    setChanged(true);
  }

  const savePatientHistory = () => {
    if(transcription && (changed || props.summary[props.step].loaded)) {
      sendComprehendMedical().then((response) => {
        setComprehend(response);
        setLoadingComprehend(false);
        props.dispatch(savePatientData({
          step: props.step,
          appointment: {
            loaded: false,
            transcription: transcription,
            transcriptionId: transcriptionId,
            comprehend: response.data,
            stepName: props.subStepName
          }
        }));
        props.dispatch(nextStep());
      }).catch(e => {
        console.log(e);
        setLoadingComprehend(false);
      });
    } else {
      props.dispatch(savePatientData({
        step: props.step,
        appointment: {
          transcription: transcription,
          transcriptionId: transcriptionId,
          comprehend: comprehend.data,
          stepName: props.subStepName
        }
      }));
      props.dispatch(nextStep());
    }

  }

  return (
    <React.Fragment>
      <div>
        <Card elevation={0} className={classes.noPadding} square>
          <CardContent>
            <Card className={classes.noBorder} variant="outlined">
              <CardContent className={classes.noPadding}>
                <AppointmentFormHeader stepName={props.stepName} stepNumber={props.stepNumber} subStepName={props.subStepName}/>
                <AppointmentFormStepName step={props.step} activeStep={props.activeStep} maxSteps={props.maxSteps}
                                         currentStep={props.currentStep}
                                         maxStepsAllForm={props.maxStepsAllForm}
                                         uploadAudio={uploadAudioHandler.bind(this)}
                                         transcribeOption={transcribeOption}
                                         updateStreamingText={updateStreamingTextHandler.bind(this)}
                                         transcribing={uploading}/>
                <AppointmentFormStepper uploading={uploading} patientName={props.patientName}
                                        motive={props.motive} stepName={props.stepName} subStepName={props.subStepName}
                                        stepNumber={props.stepNumber} maxSteps={props.maxSteps}
                                        steps={getSteps()} activeStep={props.activeStep}
                                        onChangeTranscribeOption={((value) => setTranscribeOption(value))}/>

                <TextField className={classes.marginTopTextField} id="outlined-multiline-static" label={t('transcription')} value={transcription || ''} onChange={handleTextChange} fullWidth multiline rows={4} variant="outlined"/>

                <Grid container direction="row">
                  <Grid item xs={6} md={6} className={classes.flexStart}>

                    <Button className={useStyles().buttonOtherColor} disabled={loadingComprehend}
                            style={{borderColor: '#346DFF'}} disableElevation variant="outlined"
                            onClick={() => cleanAll()}>
                      <span className={useStyles().spanButton} style={{color: '#346DFF'}}>{t('clean-all')}</span>
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.flexEnd}>
                    {!loadingComprehend ?
                        <Button className={classes.marginTopTextField} disabled={uploading} onClick={() => {
                          savePatientHistory()
                        }} variant="contained" color="secondary">
                          {t('next')}
                        </Button> :
                        <CircularProgress className={classes.marginRight}/>
                    }
                  </Grid>



                </Grid>

                <Snackbar open={missingAudio} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={closeSnackbar}>
                  <Alert onClose={closeSnackbar} color="info" severity="error">
                    {t('no-audio-saved')}
                  </Alert>
                </Snackbar>

              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
}

AppointmentStep.propTypes = {
  image: PropTypes.string,
  profession: PropTypes.string,
  practitionerName: PropTypes.string,
  step: PropTypes.string,
  stepNumber: PropTypes.string,
  stepName: PropTypes.string,
  subStepName: PropTypes.string,
  stepValue: PropTypes.number,
  patientName: PropTypes.string,
  motive: PropTypes.string,
  currentStep: PropTypes.number,
  activeStep: PropTypes.number,
  maxSteps: PropTypes.number,
  maxStepsAllForm: PropTypes.number,
};

export default connect(getSummary)(AppointmentStep)