import React, {useEffect} from 'react';
import {Skeleton} from "@material-ui/core";
import ReactECharts from 'echarts-for-react';
import {makeStyles} from '@material-ui/core/styles';
import 'react-h5-audio-player/lib/styles.css';
import LinearProgress from "@material-ui/core/LinearProgress";
import {AccountSearch} from 'mdi-material-ui';
import withStyles from "@material-ui/core/styles/withStyles";
import DataTable from 'react-data-table-component';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useHistory, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {loadExamTypes, loadHGT} from "../services/utils/load-data-services";
import {useTranslation} from "react-i18next";
import * as moment from 'moment';
import 'moment/locale/es';
import {Tab, Tabs} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/core/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import {getExam} from "../services/fhir-api-services";
import {updatePatientExams} from "../store/actions";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    displayFlex: {
        display: 'flex'
    },
    marginTop: {
        marginTop: 15
    },
    displayHidden: {
        display: 'none'
    },
    centerVertically: {
        display: 'flex',
        'align-items': 'center',
        margin: 'auto',
        'justify-content': 'center',
        'flex-wrap': 'nowrap'
    },
    centerOnlyVertically: {
        display: 'flex',
        margin: 'auto',
        'align-items': 'center',
        'flex-wrap': 'nowrap'
    },
    marginCircular: {
        margin: 10
    },

    verticalAlignBlock: {
        display: 'block',
        margin: 'auto',
        'padding': 10
    },
    paddingTopExamSelect: {
        paddingTop: 25
    },
    button: {
        [theme.breakpoints.down("md")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    buttonOtherColor: {
        borderColor: '#346DFF',
        backgroundColor: '#E6ECF2',
        [theme.breakpoints.down("md")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    rightAlign: {
        display: 'flex',
        'justify-content': 'flex-end'
    },
    displayContent: {
        display: 'contents'
    },
    spanButton: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    "MuiButton-outlinedPrimary:hover": {
        /* border: 1px solid #333333; */
        'background-color': 'rgba(51, 51, 51, 0.04)',
    },
    backButton: {
        'padding-left': 0,
        'padding-right': 0,
        'margin-right': 10
    },
    titleStyle: {
        'color': 'black'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    spaceBetween: {
        display: 'flex',
        'justifyContent': 'space-between'
    },
    displayCenterFlexEnd: {
        display: 'flex',
        'justifyContent': 'flex-end',
        'alignItems': 'center'
    },
    formControl: {
        minWidth: "100%",
    },
    buttonAddStyle: {
        [theme.breakpoints.down('md')]: {
            margin: 0,
        },
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const getData = state => ({
    user: state.user.user,
    users: state.users.users,
    patients: state.patients.patients,
    exams: state.patientExams.exams,
    types: state.patientExams.types,
});



// Other //////////


const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
    <TextField id="search" type="text" margin="dense" placeholder={filterLabelText}
               aria-label="Search Input" value={filterText} onChange={onFilter}
               InputProps={{
                   startAdornment:
                       <InputAdornment position="start">
                           <AccountSearch />
                       </InputAdornment>,
               }}/>
);

const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#F1F8FF',
            borderBottom: "2px solid",
            borderColor: "#FA6A09",
        },
    },
};


// Plot //////////
function structureData(inputData) {
    let formattedData = [
        [
            "date",
            "value",
            "unit",
        ]
    ]
    
    Object.values(inputData).forEach(exam => {
        formattedData.push([
            exam.date,
            exam.value,
            exam.unit,
        ])
    })

    return formattedData;
};

function plotExam(data, t) {
    const plot1Options = {
        grid: {
            "bottom": 90
        },
        tooltip: {
            trigger: 'item',
            formatter: (obj) => {
                return "<table>" + 
                "<tr><td>" + t("value") + ": </td>" +
                "<td align = 'right'>" + obj.data[1] + ((obj.data[2] == null) ? "" : " " + obj.data[2]) + "</td></tr>" +
                "<tr><td>" + t("date") + ": </td>" +
                "<td align = 'right'>" + moment(obj.value[0]).format("LL") + "</td></tr>" +
                "<tr><td>" + t("hour") + ": </td>" +
                "<td align = 'right'>" + moment.utc(obj.value[0]).format("HH:mm:ss") + "</td></tr>" +
                "</table>"
            }
        },
        xAxis: {
            type: 'time',
            name: t("date"),
            nameLocation: "middle",
            nameGap: 25
        },
        yAxis: {
            type: 'value',
            min: function (value) {
                return parseInt(value.min - 20);
            },
            max: function (value) {
                return parseInt(value.max + 20);
            },
            name: t("value"),
            nameLocation: "middle",
            nameGap: 40
        },
        dataZoom: [
            {   
                type: "slider",
                show: true,
                realtime: true,
                start: 70,
                end: 100,
                minValueSpan: 3600*24*1000*14,
                xAxisIndex: [0]
            },
        ],
        dataset: [
            {
                source: data,
            }
        ],
        legend: {
            top: "bottom"
        },
        series: [
            {
                type: "line",
                encode: {
                    x: "date",
                    y: "value",
                },
                symbolSize: 8
            }
        ]
    }

    return plot1Options;
};

///////////////////

const BorderLinearProgress = withStyles((theme) => ({
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        backgroundColor: '#e0e0e0',
    },
}))(LinearProgress);

const PatientExams = ({user, users, exams, types, patients, dispatch}) => {
    const classes = useStyles();
    let history = useHistory();
    const {t, i18n} = useTranslation();
    let { id } = useParams();
    const [tab, setTab] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [tryLoading, setTryLoading] = React.useState(false);
    const [filterText, setFilterText] = React.useState('');

    const [plotData, setPlotData] = React.useState([]);

    const [examType, setExamType] = React.useState({
        type: 'Glucosa capilar [masa / volumen] por glucómetro.',
    });

    const handleChangeExamType = (prop) => (event, value) => {
        if(event && event.target && (event.target.value || event.target.value === "")) {
            setExamType({ ...examType, [prop]: event.target.value});
        } else if (value) {
            setExamType({ ...examType, [prop]: value.name});
        } else {
            setExamType({ ...examType, [prop]: event});
        }
    };

    if(!patients) {
        history.push('/patients');
    }

    useEffect(() => {

        if (!types) {
            loadExamTypes(user, dispatch);
        }

    }, [user, exams])

    const handleClear = () => {
        if (filterText) {
            setFilterText('');
        }
    };

    const subHeaderComponentMemo = <Grid container direction="row" justify="space-between" alignItems="center"
                                         className={useStyles().displayContent}>
        <Grid container alignItems="flex-start" item={true} xs={6}>

        </Grid>
        <Grid container item={true} xs={6} className={useStyles().rightAlign}>
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText}
                             filterLabelText={t('filter-label-text')}/>
        </Grid>

    </Grid>;

    const columns = [
        {
            name: t('date'),
            selector: 'date',
            sortable: true,
        },
        {
            name: t('value'),
            selector: 'value',
            sortable: true,
            right: true,
        },
        {
            name: t('measurement'),
            selector: 'unit',
            sortable: true,
            right: true,
            hide: 'sm'
        },
    ];

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleChangeIndex = (index) => {
        setTab(index);
    };

    const loadExams = () => {
        if(types && user) {
            setLoading(true);
            let examsArray = [];
            getExam(id, types.find(t => t.name === examType.type).code, user.signInUserSession.idToken.jwtToken,
                user.signInUserSession.accessToken.jwtToken).then((response) => {
                    if(response.data.exam_data) {

                        response.data.exam_data.forEach(exam => {
                            examsArray.push({
                                date: moment.utc(exam.date).format("YYYY/MM/DD HH:mm:ss a"),
                                unit: exam.unit,
                                value: exam.value
                            })
                        })

                        setPlotData(structureData(response.data.exam_data));
                    }
                
                dispatch(updatePatientExams(examsArray));
                setTryLoading(true);
                setLoading(false);
            }).catch((e) => {
                console.log(e);
                dispatch(updatePatientExams(examsArray));
                setLoading(false);
            });
        }
    }

    const filteredItems = exams? exams.filter(item =>
        (item.value && item.value.toString().includes(filterText.toLowerCase()))) : [];

    const currentUser = user && users? users.find(u => u.username === user.username): null;

    return (
        <React.Fragment>
            <div>
                <Grid item xs={'auto'} className={classes.verticalAlignBlock}>
                    <Grid container direction="row" className={classes.spaceBetween}>
                        <Grid item xs={'auto'}>
                            <Typography>
                                {t('user-area')}: {currentUser ? t(currentUser.practitioner) : t('user-not-defined')}
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography>
                                {t('user-medical-center')}: {currentUser ? (currentUser.organization) : t('user-not-defined')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.spaceBetween}>
                        <Grid item xs={'auto'}>
                            <Typography variant="h5" gutterBottom>
                                <b>{currentUser ? (currentUser.name || currentUser.username) : t('user-no-name')}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h7" gutterBottom>
                                {moment().locale('es').format('llll')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Tabs value={tab} onChange={handleChange}
                      indicatorColor="primary" textColor="primary" variant="fullWidth"
                      aria-label="full width tabs example">
                    <Tab label={t('data')} {...a11yProps(0)} />
                    <Tab label={t('analysis')} {...a11yProps(1)} />
                </Tabs>


                <Grid item xs={'auto'} className={clsx(classes.verticalAlignBlock, classes.paddingTopExamSelect)}>
                    <Grid container direction="row" className={classes.spaceBetween}>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" className={clsx(classes.formControl)}>
                                <Autocomplete options={types? types: []} getOptionLabel={(option) => option.name} disableClearable
                                              getOptionSelected={(option, value) => option.code === value.code}
                                              onChange={handleChangeExamType('type')}
                                              getOptionLabel={(option) => !option.name ? "Glucosa capilar [masa / volumen] por glucómetro." : option.name}
                                              defaultValue="Glucosa capilar [masa / volumen] por glucómetro."
                                              noOptionsText={t('patient-appointment-no-exam-found')}
                                              renderInput={(params) =>
                                                  <TextField {...params} value={examType.type}
                                                             label={t('exam')} variant="outlined" />}>
                                </Autocomplete>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} className={classes.displayCenterFlexEnd}>
                            {
                                loading ?
                                    <CircularProgress className={classes.marginCircular}/> :
                                    <div>
                                        <Button variant="contained" color="secondary" onClick={() => loadExams()}>
                                            <span className={classes.whiteText}>{t('load-exams')}</span>
                                        </Button>
                                    </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={tab} index={0}>
                        <DataTable
                            noHeader
                            subHeader
                            striped
                            persistTableHead
                            responsive
                            pagination
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('elements-per-page'),
                                    rangeSeparatorText: t('range-separator-text'),
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: t('select-all-rows')
                                }
                            }
                            noDataComponent={tryLoading ? t('patient-appointment-no-exam-found') : t('patient-appointment-no-exam-loaded')}
                            subHeaderComponent={subHeaderComponentMemo}
                            subHeaderAlign="left"
                            columns={columns}
                            customStyles={customStyles}
                            data={filteredItems}
                        />
                        {!types? <BorderLinearProgress/> : null}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                    {   
                        (plotData.length<=1) ?
                            <>
                            <Skeleton animation="wave" height={1000} style={{marginTop: -150}}/>
                            </>: 
                            <ReactECharts
                            option={plotExam(plotData, t)}
                            style={{marginTop: 20, height: 600}}
                            />
                    }
                    </TabPanel>

                </SwipeableViews>
            </div>
        </React.Fragment>
    );
}

export default connect(getData)(PatientExams)
