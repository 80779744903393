const patientExpertSystem = (state = {expertSystem: null}, action) => {
    switch (action.type) {
        case 'UPDATE_EXPERT_SYSTEM':
            return {
                ...state,
                expertSystem: action.expertSystem
            };
        default:
            return state
    }
};

export default patientExpertSystem;