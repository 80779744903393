const statisticsPlotData = (state = {data: null}, action) => {
    switch (action.type) {
        case 'UPDATE_STATISTICS_DATA':
            return {
                ...state,
                data: action.data
            };
        default:
            return state
    }
};

export default statisticsPlotData;