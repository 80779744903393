import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    selected: {
        color: theme.palette.secondary.main,
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        borderLeft: "5px solid"
    },
    colorSelected: {
        color: theme.palette.secondary.main,
    }
}));

function NavigationItem({ disabled = false, icon, primary, to }) {
    const location = useLocation();
    const classes = useStyles();

    return (
        <div>
            {!disabled ?
                <Link style={{textDecoration: 'none', color: 'black'}} to={to}>
                    <ListItem classes={{selected: classes.selected}} button selected={to === location.pathname}
                              disabled={disabled}>
                        {icon ? <ListItemIcon
                            className={clsx({[classes.colorSelected]: to === location.pathname})}>{icon}</ListItemIcon> : null}
                        <ListItemText primary={primary}/>
                    </ListItem>
                </Link> :
                <ListItem classes={{selected: classes.selected}} button selected={to === location.pathname}
                          disabled={disabled}>
                    {icon ? <ListItemIcon
                        className={clsx({[classes.colorSelected]: to === location.pathname})}>{icon}</ListItemIcon> : null}
                    <ListItemText primary={primary}/>
                </ListItem>}
        </div>
    )
}

NavigationItem.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}

export default NavigationItem;