import React, {forwardRef, useImperativeHandle} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {CloseCircleOutline} from 'mdi-material-ui';
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/core/Autocomplete";
import {createUserFromApi, editUserFromApi} from "../../services/fhir-api-services";
import {updateExpertSystem, updatePatientAppointments} from "../../store/actions";
import {loadPatients} from "../../services/utils/load-data-services";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {checkExpertSystem, createExpertSystem} from "../../services/expert-system-services";

const useStyles = makeStyles((theme) => ({
    verticalAlign: {
        display: 'flex',
        margin: 'auto'
    },
    displayFlex: {
        display: 'flex'
    },
    verticalAlignBlock: {
        display: 'block',
        margin: 'auto'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    appBarStyle: {
        "backgroundColor": "#FFF",
        borderRadius: '4px',
        borderBottom: '1px solid #D3DCE2',
        marginBottom: '15px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1), inset 0px 3px 0px #FA6A09'
    },
    titleStyle: {
        'color': 'black',
        fontFamily: 'Titillium Web',
        fontWeight: 'bold'
    },
    rightAlign: {
        display: 'flex',
        'justify-content': 'flex-end'
    },
    displayContent: {
        display: 'contents'
    },
    marginDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    formControl: {
        minWidth: "100%",
    },
    textFieldWhite: {
        borderWidth: "1px",
        borderColor: "white !important",
    },
    marginPerGrid: {
        marginTop: theme.spacing(1),
    },
    whiteText: {
        color: 'white',
    },
}));

const ExpertSystemForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [expertSystem, setExpertSystem] = React.useState(null);
    const [isEdit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [values, setValue] = React.useState({
        hba1c: '',
    });

    const handleValueChange = (prop) => (event, value) => {
        setValue({ ...values, [prop]: event.target.value || (value? value.name: '') });
    };


    const saveExpertSystem = () => {
        setLoading(true);
        checkExpertSystem(expertSystem['expert_id'], values.hba1c, user.signInUserSession.idToken.jwtToken).then((response) => {
            setLoading(false);
            setOpen(false);
            props.checkExpert(response.data);
        }).catch((e) => {
            console.log(e);
            setLoading(false);
        });

    };

    useImperativeHandle(ref, () => ({

        openDialog(expert, user, isEdit) {
            setEdit(isEdit);
            setOpen(true);
            setUser(user);
            setExpertSystem(expert);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} fullWidth={true}
                    maxWidth={'sm'} aria-labelledby="form-dialog-title">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <AppBar position="static" className={classes.appBarStyle} elevation={0} >
                        <Toolbar variant="dense">
                            <Grid container direction="row" justify="space-between" alignItems="center"
                                  className={useStyles().displayContent}>
                                <Grid container item={true} xs={8}>
                                    <Typography component="h2" variant="h6" className={useStyles().titleStyle}>
                                        {t('expertSystemTitle')}
                                    </Typography>
                                </Grid>
                                <Grid container alignItems="flex-end" className={useStyles().rightAlign} item={true} xs={4}>
                                    <IconButton color="primary" aria-label="close button" onClick={handleClose} >
                                        <CloseCircleOutline/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <DialogContentText>
                            {t('expertSystemDescription')}
                        </DialogContentText>
                        <Divider className={classes.marginDivider}/>
                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense" value={values.hba1c}
                                           onChange={handleValueChange('hba1c')}
                                           label={t('HbA1c')} fullWidth/>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        {
                            loading ?
                                <CircularProgress className={classes.marginCircular}/> :
                                <div>
                                    <Button variant="contained" color="secondary" onClick={() => saveExpertSystem()}>
                                        <span className={classes.whiteText}>{t('save')}</span>
                                    </Button>
                                </div>
                        }
                    </DialogActions>
                </MuiPickersUtilsProvider>
            </Dialog>
        </React.Fragment>
    );
});

export default ExpertSystemForm;