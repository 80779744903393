import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import 'react-h5-audio-player/lib/styles.css';
import LinearProgress from "@material-ui/core/LinearProgress";
import {AccountPlus, AccountSearch, MicrophonePlus, PencilOutline} from 'mdi-material-ui'
import withStyles from "@material-ui/core/styles/withStyles";
import DataTable from 'react-data-table-component';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import OrganizationForm from "../components/forms/OrganizationForm";
import {loadOrganizations, loadPatients, loadPractitioners, loadUsers} from "../services/utils/load-data-services";
import * as moment from "moment";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: 'flex'
  },
  marginTop: {
    marginTop: 15
  },
  displayHidden: {
    display: 'none'
  },
  centerVertically: {
    display: 'flex',
    'align-items': 'center',
    margin: 'auto',
    'justify-content': 'center',
    'flex-wrap': 'nowrap'
  },
  marginCircular: {
    margin: 10
  },
  spaceBetween: {
    display: 'flex',
    'justifyContent': 'space-between'
  },
  verticalAlignBlock: {
    display: 'block',
    margin: 'auto',
    'padding': 10
  },
  rightAlign: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#e0e0e0',
  },
}))(LinearProgress);

const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
    <TextField id="search" type="text" margin="dense" placeholder={filterLabelText}
               aria-label="Search Input" value={filterText} onChange={onFilter}
               InputProps={{
                 startAdornment:
                     <InputAdornment position="start">
                       <AccountSearch />
                     </InputAdornment>,
               }}/>
);

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#F1F8FF',
      borderBottom: "2px solid",
      borderColor: "#FA6A09",
    },
  },
};

const getData = state => ({
  user: state.user.user,
  users: state.users.users,
  organizations: state.organizations.organizations,
});

const Organizations = ({user, users, organizations, dispatch}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  const [filterText, setFilterText] = React.useState('');
  const [perPage, setPerPage] = React.useState(10);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    if(!users) {
      loadUsers(user, dispatch);
    }

    if (!organizations) {
      loadOrganizations(user, dispatch);
    }
  }, [user]);

  const handleClickOpen = (org, isEdit) => {
    dialogRef.current.openDialog(org, user, isEdit);
  };
  const handleClear = () => {
    if (filterText) {
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = <Grid container direction="row" justify="space-between" alignItems="center"
                                       className={useStyles().displayContent}>
    <Grid container alignItems="flex-start" item={true} xs={6}>
    </Grid>
    <Grid container item={true} xs={6} className={useStyles().rightAlign}>
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                       filterText={filterText} filterLabelText={t('organization-filter-label-text')}/>
    </Grid>
  </Grid>;

  const columns = [
    {
      name: t('organization-columns-name'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('organization-columns-code'),
      selector: 'code',
      sortable: true,
      right: true,
      hide: 'sm'
    },
    {
      name: t('organization-columns-address'),
      selector: 'addressN',
      sortable: true,
      right: true,
      hide: 'md'
    },
    {
      name: t('organization-columns-phone-number'),
      selector: 'phone',
      sortable: true,
      right: true,
      hide: 'md'
    },
    {
      name: t('organization-columns-actions'),
      cell: (a) => <Grid container className={classes.centerVertically}>
        <IconButton size="small" style={{backgroundColor: '#E6ECF2'}} onClick={() => handleClickOpen(a, true)}>
          <PencilOutline color="secondary"/>
        </IconButton>
      </Grid>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: false
    },
  ];

  const filteredItems = organizations? organizations.filter(item =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.code && item.code.toString().includes(filterText.toLowerCase())) ||
      (item.addressN && item.addressN.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phone && item.phone.toLowerCase().includes(filterText.toLowerCase())))
      : [];

  const currentUser = user && users? users.find(u => u.username === user.username): null;

  return (
      <React.Fragment>
        <>
          <Grid item xs={'auto'} className={classes.verticalAlignBlock}>
            <Grid container direction="row" className={classes.spaceBetween}>
              <Grid item xs={'auto'}>
                <Typography>
                  {t('user-area')}: {currentUser ? t(currentUser.practitioner) : t('user-not-defined')}
                </Typography>
              </Grid>
              <Grid item xs={'auto'}>
                <Typography>
                  {t('user-medical-center')}: {currentUser ? (currentUser.organization) : t('user-not-defined')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.spaceBetween}>
              <Grid item xs={'auto'}>
                <Typography variant="h5" gutterBottom>
                  <b>{currentUser ? (currentUser.name || currentUser.username) : t('user-no-name')}</b>
                </Typography>
              </Grid>
              <Grid item xs={'auto'}>
                <Typography variant="h7" gutterBottom>
                  {moment().locale('es').format('llll')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*<Title title="Organizaciones"/>*/}
          <DataTable noHeader subHeader striped persistTableHead
                     noDataComponent={t('organization-loading-organizations')}
                     subHeaderComponent={subHeaderComponentMemo}
                     subHeaderAlign="left"
                     pagination
                     paginationComponentOptions={
                       {
                         rowsPerPageText: t('elements-per-page'),
                         rangeSeparatorText: t('range-separator-text'),
                         noRowsPerPage: false,
                         selectAllRowsItem: false,
                         selectAllRowsItemText: t('select-all-rows')
                       }
                     }
                     columns={columns}
                     customStyles={customStyles}
                     data={filteredItems}
          />
          {!organizations ? <BorderLinearProgress/> : null}
          <OrganizationForm ref={dialogRef} reload={() => loadOrganizations(user, dispatch)}/>
        </>
      </React.Fragment>
  );
}

export default connect(getData)(Organizations)
