import React, {forwardRef, useImperativeHandle} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {CloseCircleOutline} from 'mdi-material-ui';
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {createUserFromApi, editOrganization, editUserFromApi} from "../../services/fhir-api-services";

const useStyles = makeStyles((theme) => ({
    verticalAlign: {
        display: 'flex',
        margin: 'auto'
    },
    displayFlex: {
        display: 'flex'
    },
    verticalAlignBlock: {
        display: 'block',
        margin: 'auto'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    appBarStyle: {
        "backgroundColor": "#FFF",
        borderRadius: '4px',
        borderBottom: '1px solid #D3DCE2',
        marginBottom: '15px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1), inset 0px 3px 0px #FA6A09'
    },
    titleStyle: {
        'color': 'black',
        fontFamily: 'Titillium Web',
        fontWeight: 'bold'
    },
    rightAlign: {
        display: 'flex',
        'justify-content': 'flex-end'
    },
    displayContent: {
        display: 'contents'
    },
    marginDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    formControl: {
        minWidth: "100%",
    },
    textFieldWhite: {
        borderWidth: "1px",
        borderColor: "white !important",
    },
    marginPerGrid: {
        marginTop: theme.spacing(1),
    },
    whiteText: {
        color: 'white',
    },
}));

const UserForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [isEdit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [organization, setOrganization] = React.useState({
        name: '',
        code: '',
        address: '',
        phone: '',
        district: '',
        country: '',
    });

    const [errors, setError] = React.useState({
        name: {
            error: false,
            errorMessage: '',
            mandatoryMessage: 'mandatoryField',
            mandatory: true
        },
        code: {
            error: false,
            errorMessage: '',
        },
        address: {
            error: false,
            errorMessage: '',
            mandatoryMessage: 'mandatoryField',
            mandatory: true
        },
        phone: {
            error: false,
            errorMessage: '',
        },
        district: {
            error: false,
            errorMessage: '',
            mandatoryMessage: 'mandatoryField',
            mandatory: true
        },
        country: {
            error: false,
            errorMessage: '',
            mandatoryMessage: 'mandatoryField',
            mandatory: true
        },
    });

    const handleOrganizationValueChange = (prop) => (event, value) => {
        setOrganization({ ...organization, [prop]: event.target.value });
        setError({ ...errors, [prop]: Object.assign(errors[prop],  {error: false, errorMessage: ''} ) });
    };

    const saveOrganization = () => {
        if(validateForm()) {
            setLoading(true);
            if (isEdit) {
                editOrganization({
                        id: organization.id,
                        identifier: organization.code,
                        healthService: 'Servicio de Salud Arica',
                        name: organization.name,
                        phone: organization.phone,
                        address: organization.address,
                        district: organization.district,
                        country: organization.country,
                    },
                    user.signInUserSession.idToken.jwtToken,
                    user.signInUserSession.accessToken.jwtToken)
                    .then((response) => {
                        props.reload();
                        setLoading(false);
                        setOpen(false);
                    })
                    .catch((e) => {
                        console.log(e);
                        setLoading(false);
                        setOpen(false);
                    });
            } else {
                setLoading(true);
            }
        }
    };

    useImperativeHandle(ref, () => ({

        openDialog(organizationObj, user, isEdit) {
            setEdit(isEdit);
            setOpen(true);
            setUser(user);
            if(organizationObj) {
                setOrganization({
                    id: organizationObj.id,
                    name: organizationObj.name,
                    code: organizationObj.code,
                    address: organizationObj.addressN,
                    phone: organizationObj.phone,
                    district: organizationObj.district,
                    country: organizationObj.country,
                })
            }
        }
    }));

    const validateForm = () => {
        let validate = true;

        Object.entries(errors).forEach((array, index) => {

            const key = array[0];
            const value = array[1];

            if(value.mandatory && (!organization[key] || organization[key] === '')) {
                validate = false;
                setError({ ...errors, [key]:  Object.assign(value,
                        {
                            error: true,
                            errorMessage: value.mandatoryMessage
                        })})
            }
        });

        return validate;
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} fullWidth={true}
                    maxWidth={'sm'} aria-labelledby="form-dialog-title">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <AppBar position="static" className={classes.appBarStyle} elevation={0} >
                        <Toolbar variant="dense">
                            <Grid container direction="row" justify="space-between" alignItems="center"
                                  className={useStyles().displayContent}>
                                <Grid container item={true} xs={'auto'}>
                                    <Typography component="h2" variant="h6" className={useStyles().titleStyle}>
                                        {isEdit? t('editOrganization'): t('newOrganization')}
                                    </Typography>
                                </Grid>
                                <Grid container alignItems="flex-end" className={useStyles().rightAlign} item={true} xs={'auto'}>
                                    <IconButton color="primary" aria-label="close button" onClick={handleClose} >
                                        <CloseCircleOutline/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <DialogContent >
                        <DialogContentText>
                            {isEdit? t('editOrganizationDescription'): t('newOrganizationDescription')}
                        </DialogContentText>
                        <Divider className={classes.marginDivider}/>
                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense"
                                           value={organization.name}
                                           error={errors.name.error}
                                           helperText={t(errors.name.errorMessage)}
                                           onChange={handleOrganizationValueChange('name')}
                                           label={t('name')} fullWidth/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense"
                                           value={organization.code}
                                           error={errors.code.error}
                                           helperText={t(errors.code.errorMessage)}
                                           onChange={handleOrganizationValueChange('code')}
                                           label={t('code')} fullWidth/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense"
                                           value={organization.address}
                                           error={errors.address.error}
                                           helperText={t(errors.address.errorMessage)}
                                           onChange={handleOrganizationValueChange('address')}
                                           label={t('address')} fullWidth/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense"
                                           value={organization.phone}
                                           error={errors.phone.error}
                                           helperText={t(errors.phone.errorMessage)}
                                           onChange={handleOrganizationValueChange('phone')}
                                           label={t('phone')} fullWidth/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense"
                                           value={organization.district}
                                           error={errors.district.error}
                                           helperText={t(errors.district.errorMessage)}
                                           onChange={handleOrganizationValueChange('district')}
                                           label={t('district')} fullWidth/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.marginPerGrid}>
                            <Grid item xs={12}>
                                <TextField margin="dense"
                                           value={organization.country}
                                           error={errors.country.error}
                                           helperText={t(errors.country.errorMessage)}
                                           onChange={handleOrganizationValueChange('country')}
                                           label={t('country')} fullWidth/>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        {
                            loading ?
                                <CircularProgress className={classes.marginCircular}/> :
                                <div>
                                    <Button variant="contained" color="secondary" onClick={() => saveOrganization()}>
                                        <span className={classes.whiteText}>{t('save')}</span>
                                    </Button>
                                </div>
                        }
                    </DialogActions>
                </MuiPickersUtilsProvider>
            </Dialog>
        </React.Fragment>
    );
});

export default UserForm;