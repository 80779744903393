const practitioners = (state = {practitioners: null}, action) => {
    switch (action.type) {
        case 'UPDATE_PRACTITIONERS':
            return {
                ...state,
                practitioners: action.practitioners
            };
        default:
            return state
    }
};

export default practitioners;