import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link,} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textField: {
        width: '100%',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    marginDivider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        "border-color": "rgba(255, 255, 255, 0.5)"
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    welcome: {
        marginTop: theme.spacing(10),
        color: 'white'
    },
    margin: {
        margin: theme.spacing(1),
    },
    forgotPassword: {
        marginTop: theme.spacing(3),
        "text-align": "center",
        color: 'white'
    },
    whiteText: {
        color: 'white',
    },
    textFieldWhite: {
        borderWidth: "1px",
        borderColor: "white !important",
    },
    title: {
        'font-size': '45px'
    },
    imageIcon: {
        height: '100%',
        width: '100%'
    },
    iconRoot: {
        marginLeft: theme.spacing(1),
        textAlign: 'center'
    },
    justifyCenterVertical: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
    }
}));

export default function ChangePassword() {
    const classes = useStyles();

    const {t, i18n} = useTranslation();
    const [values, setValues] = React.useState({
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container justifyContent="center" className={classes.justifyCenterVertical}>
                    <Typography component="h1" variant="h5" className={clsx(classes.title, classes.whiteText)}>
                        VOYAGER
                    </Typography>
                    <Icon fontSize="large" classes={{root: classes.iconRoot}}>
                        <img className={classes.imageIcon} src="/logo-unit.svg"/>
                    </Icon>
                </Grid>

                <Typography className={classes.welcome}>
                    {t('change-password-insert-new-password')}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        label={t('change-password-password-label')}
                        autoComplete="current-password"
                        className={clsx(classes.textField,classes.whiteText)}
                        value={values.password}
                        type={values.showPassword ? 'text' : 'password'}
                        onChange={handleChange('password')}
                        InputLabelProps={{
                            style: { color: 'white' },
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.textFieldWhite,
                                root: classes.whiteText
                            },
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword} edge="end">
                                        {values.showPassword ?
                                            <Visibility className={classes.whiteText} /> :
                                            <VisibilityOff className={classes.whiteText} />}
                                    </IconButton>
                                </InputAdornment>,
                        }}
                        variant="outlined"
                    />

                    <TextField
                        label={t('change-password-confirm-password-label')}
                        autoComplete="current-password"
                        className={clsx(classes.margin, classes.textField,classes.whiteText)}
                        value={values.password}
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        onChange={handleChange('confirmPassword')}
                        InputLabelProps={{
                            style: { color: 'white' },
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.textFieldWhite,
                                root: classes.whiteText
                            },
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword} edge="end">
                                        {values.showPassword ?
                                            <Visibility className={classes.whiteText} /> :
                                            <VisibilityOff className={classes.whiteText} />}
                                    </IconButton>
                                </InputAdornment>,
                        }}
                        variant="outlined"
                    />
                    <Link style={{ textDecoration: 'none' }} to={`/login`}>
                        <Button fullWidth variant="contained" style={{'backgroundColor': '#FA6A09'}} className={classes.submit}>
                            <span className={classes.whiteText}>{t('change-password-change')}</span>
                        </Button>
                    </Link>

                </form>
            </div>
        </Container>
    );
}