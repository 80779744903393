const patients = (state = {patients: null}, action) => {
  switch (action.type) {
    case 'UPDATE_PATIENTS':
      return {
        ...state,
        patients: action.patients
      };
    default:
      return state
  }
};

export default patients;