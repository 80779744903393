import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import 'react-h5-audio-player/lib/styles.css';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from "../components/Title";
import {useHistory, useParams} from "react-router-dom";
import {previousStep, updatePatientEncounterDetails} from "../store/actions";
import {searchEncounter} from "../services/fhir-api-services";
import {InputLabel, Select, Skeleton} from "@material-ui/core";
import AppointmentFormHeader from "../components/appointment/AppointmentFormHeader";
import AppointmentFormStepName from "../components/appointment/AppointmentFormStepName";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import {AccountTieOutline, AccountOutline, Calendar, CalendarRange, Lan} from "mdi-material-ui";
import Divider from "@material-ui/core/Divider";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: '0 !important'
  },
  noBorder: {
    'border': 'none',
    'padding-bottom': 0
  },
  marginTopTextField: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    'justify-content': 'flex-end'
  },
  textAreaTitle: {
    'font-size': 18,
    'font-weight': 500,
    'text-align': 'left'
  },
  resultSubtitle: {
    'margin-top': theme.spacing(1),
    'font-size': 15,
    'font-weight': 500,
    'text-align': 'left'
  },
  resultTitle: {
    'margin-top': theme.spacing(1),
    'font-size': 16 ,
    'font-weight': 500,
    'text-align': 'left'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  buttonClass: {
    'justify-content': 'flex-end',
    'margin-bottom': 'auto',
    'margin-top': 'auto',
    'align-items': 'center',
    'display': 'block'
  },
  justifySpaceBetween: {
    'margin-top': theme.spacing(6),
    'margin-bottom': theme.spacing(3),
    'display': 'flex',
    'justify-content': 'space-between'
  },
  iconTransparent: {
    color: "transparent",
    marginRight: '10px'
  },
  fontValueSize: {
    fontSize: '18px'
  },
  iconTitleData: {
    color: '#021842',
    marginRight: '10px'
  },
  fontTitleData: {
    fontSize: '16px',
    color: '#021842',
    fontWeight: 600
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

const getAppointment = state => ({
  appointment: state.patientEncounterDetails.appointment,
  user: state.user.user,
  practitioners: state.practitioners.practitioners,
  organizations: state.organizations.organizations,
  appointments: state.patientAppointments.appointments,
});

const AppointmentSummary = ({ appointment, appointments, user, practitioners, organizations, dispatch }) => {
  let history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let { id } = useParams();
  const {t, i18n} = useTranslation();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchEncounterById = (id) => {
    if(user) {
      searchEncounter(id, user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken).then((response) => {
        dispatch(updatePatientEncounterDetails(response.data.conclusion !== ""?  JSON.parse(response.data.conclusion): {}));
      }).catch((e) => {
        dispatch(updatePatientEncounterDetails( {}));
        console.log(e);
      });
    }
  }

  if(!appointment) {
    searchEncounterById(id);
  }

  let stepsResults = appointment? Object.entries(appointment).sort((a, b) => a[1].step - b[1].step).map(step => {
    return {
      step: step[1].step + 1,
      stepName: step[1].stepName,
      name: step[0],
      comprehend: step[1].comprehend,
      transcription: step[1].transcription
    }
  }): [];

  let currentAppointment = appointments? appointments.find(a => a.id === id) : {};

  return (
    <React.Fragment>
      <div>
        <AppBar style={{"backgroundColor": "#F1F8FF", paddingBottom: '2px'}} position="static">
          <Toolbar style={{"backgroundColor": "#FFF", "borderRadius": "0px 0px 20px 20px", boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'}}>
            <Grid container direction="row" style={{'display': 'flex', 'justifyContent': 'space-between', alignItems: 'center'}}>
              <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-start'}}>
                <IconButton size="small" color="primary" className={useStyles().backButton} onClick={() => {
                  history.go(-1)
                }}>
                  <ChevronLeftIcon/>
                </IconButton>
              </Grid>
              <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography component="h2" style={{fontSize: '18px'}} variant="h6" color="primary" className={useStyles().white}>
                  {t('attention-history')}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>

              </Grid>

            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar elevation={0} style={{"backgroundColor": "#FFF"}} position="static">
          <Toolbar style={{"backgroundColor": "#F1F8FF", "paddingTop": '10px', "paddingBottom": "10px", "borderRadius": "0px 0px 20px 20px"}}>
            <Grid container direction="column">
              <Grid container direction="row" style={{'display': 'flex', 'justifyContent': 'space-between', alignItems: 'center'}}>
                <Grid item xs={6} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountTieOutline className={classes.iconTitleData}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                            {t('professional')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountTieOutline className={classes.iconTransparent}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                            {currentAppointment.practitionerName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={6} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountOutline className={classes.iconTitleData}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                            {t('patient')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountOutline className={classes.iconTransparent}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                            {currentAppointment.patientName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
              <Divider style={{marginTop: 10, marginBottom: 10}}/>
              <Grid container direction="row" style={{'display': 'flex', 'justifyContent': 'space-between', alignItems: 'center'}}>

                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTitleData}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                            {t('modality')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTransparent}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                            {currentAppointment.appointmentName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTitleData}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                            {t('appointment-date')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTransparent}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                            {currentAppointment.date}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTitleData}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                            {t('registered-date')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTransparent}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                            {currentAppointment.created || currentAppointment.date}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <Lan className={classes.iconTitleData}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                            {t('clinical-area')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <Lan className={classes.iconTransparent}/>
                        </Grid>
                        <Grid item>
                          <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                            {currentAppointment.practitioner}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Card className={classes.noPadding}>
          <CardContent>
            <Card className={classes.noBorder} variant="outlined">
              <CardContent className={classes.noPadding}>

                <Typography className={classes.textAreaTitle} gutterBottom>
                  {t('summary-step-subtitle')}
                </Typography>

                {!appointment ?
                    <>
                      <Skeleton animation="wave" height={80} width="100%" style={{marginBottom: 3}}/>
                      <Skeleton animation="wave" height={80} width="100%" style={{marginBottom: 3}}/>
                    </> :
                    <></>}
                {stepsResults.map((step, index) => (
                    step.transcription !== ''?
                        <Accordion style={{border: 'none'}} expanded={expanded === ('panel' + step.step)} onChange={handleChange(('panel' + step.step))} key={index}>
                          <AccordionSummary style={{background: '#F1F8FF', marginBottom: '15px', border: '1px solid rgba(0, 0, 0, .125)'}} expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography className={classes.heading}>{t('step') + ' ' + step.step}</Typography>
                            <Typography className={classes.secondaryHeading}>{step.stepName}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>

                            <TextField className={classes.marginTopTextField} id="outlined-multiline-static"
                                       label={t('transcription')} value={step.transcription}
                                       disabled fullWidth multiline rows={4} variant="outlined">
                            </TextField>

                            {(step.comprehend && step.comprehend.condition_entities && step.comprehend.condition_entities.length > 0)?
                                <Typography className={classes.resultSubtitle} gutterBottom>
                                  {t('findings')}
                                </Typography>
                                : null}

                            {(step.comprehend && step.comprehend.condition_entities)?
                                step.comprehend.condition_entities.map((value, index) => {
                                  return <Grid container spacing={1} key={index}>
                                    <Grid item xs={4}>
                                      <TextField margin="dense" value={value.finding} onChange={(event) => {}}
                                                 label={t('finding')} fullWidth/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField margin="dense"
                                                 value={value.presence? t('presence-single'): t('missing')}
                                                 onChange={(event) => {}}
                                                 label={t('presence')} fullWidth/>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField margin="dense"
                                                 value={value.attributes.length > 0? value.attributes.map(attr => attr.Type + ": " + attr.Text_es).join(","): "- - - - -"}
                                                 onChange={(event) => {}}
                                                 label={t('attributes')} fullWidth/>
                                    </Grid>
                                  </Grid>
                                }): null}

                            {(step.comprehend && step.comprehend.pharma_entities && step.comprehend.pharma_entities.length > 0)?
                                <Typography className={classes.resultSubtitle} gutterBottom>
                                  {t('medicines')}
                                </Typography>
                                : null}

                            {(step.comprehend && step.comprehend.pharma_entities)? step.comprehend.pharma_entities.map((value, index) => {
                              return <Grid container spacing={1} key={index}>
                                <Grid item xs={3}>
                                  <TextField margin="dense"
                                             value={value.name}
                                             onChange={(event) => {}}
                                             label={t('name')} fullWidth/>
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField margin="dense"
                                             value={value.composition}
                                             onChange={(event) => {}}
                                             label={t('composition')} fullWidth/>
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField margin="dense"
                                             value={value.dosage}
                                             onChange={(event) => {}}
                                             label={t('dosage')} fullWidth/>
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField margin="dense"
                                             value={value.frequency}
                                             onChange={(event) => {}}
                                             label={t('frequency')} fullWidth/>
                                </Grid>
                              </Grid>
                            }): null}

                            {(step.comprehend && step.comprehend.test_entities && step.comprehend.test_entities.length > 0)?
                                <Typography className={classes.resultSubtitle} gutterBottom>
                                  {t('exams')}
                                </Typography> : null}

                            {(step.comprehend && step.comprehend.test_entities)? step.comprehend.test_entities.map((value, index) => {
                              return <Grid container spacing={1} key={index}>
                                <Grid item xs={4}>
                                  <TextField margin="dense"
                                             value={value.name}
                                             onChange={(event) => {}}
                                             label={t('name')} fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField margin="dense"
                                             value={value.value}
                                             onChange={(event) => {}}
                                             label={t('value')} fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField margin="dense"
                                             value={value.unit}
                                             onChange={(event) => {}}
                                             label={t('unit')} fullWidth/>
                                </Grid>
                              </Grid>
                            }): null}

                          </AccordionDetails>
                        </Accordion> : null
                ))}
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default connect(getAppointment)(AppointmentSummary)