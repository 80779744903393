import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import 'react-h5-audio-player/lib/styles.css';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import {fabClasses, Tab, Tabs} from "@material-ui/core";
import {CalendarToday, TimerOutline} from "mdi-material-ui";
import {useTranslation} from "react-i18next";
import SwipeableViews from "react-swipeable-views";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import ReactECharts from 'echarts-for-react';
import {Skeleton} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {loadOrganizations, loadPractitioners, loadUsers, loadStatisticsData} from "../services/utils/load-data-services";

const useStyles = makeStyles((theme) => ({
    // plots: {
    //     ".echarts-for-react": {
    //         height: "600px"
    //     },
    //     marginTop: 20
    // },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    displayFlex: {
        display: 'flex'
    },
    marginTop: {
        marginTop: 15
    },
    displayHidden: {
        display: 'none'
    },
    centerVertically: {
        display: 'flex',
        'align-items': 'center',
        margin: 'auto',
        'justify-content': 'center',
        'flex-wrap': 'nowrap'
    },
    marginCircular: {
        margin: 10
    },

    verticalAlignBlock: {
        display: 'block',
        margin: 'auto',
        'padding': 10
    },
    spaceBetween: {
        display: 'flex',
        'justifyContent': 'space-between'
    },
    rightAlign: {
        display: 'flex',
        'justify-content': 'flex-end'
    },
    displayContent: {
        display: 'contents'
    },
    button: {
        [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    buttonOtherColor: {
        backgroundColor: '#E6ECF2',
        [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    spanButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    buttonAddStyle: {
        color: '#346DFF',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const getData = state => ({
    user: state.user.user,
    users: state.users.users,
    organizations: state.organizations.organizations,
    practitioners: state.practitioners.practitioners,
    statisticsPlotData: state.statisticsPlotData.data
});

const tabs = [
    {name: "tab-allday", icon: <CalendarToday/>},
    {name: "tab-ampm", icon: <TimerOutline/>}
];

// Plots ///////////////////////////////////////////////////////////

function structureData(inputData) {
    let formattedData = [
        [
            "key",
            "age",
            "hemogluco-mean",
            "hemogluco-pm",
            "hemogluco-am",
            "gender",
            "hemogluco-count",
            "identifier"
        ]
    ]
    
    Object.keys(inputData["data"]).forEach(key => {
        formattedData.push([
            key,
            inputData["data"][key]["age"],
            inputData["data"][key]["hemogluco-mean"],
            inputData["data"][key]["hemogluco-pm"],
            inputData["data"][key]["hemogluco-am"],
            inputData["data"][key]["gender"],
            inputData["data"][key]["hemogluco-count"],
            inputData["data"][key]["identifier"],
        ])
    })

    return formattedData;
};

const genderColor = {
    "male": "#346DFF",
    "female": "#fa6a09",
    "other": "#808080"
};

function plot1Create(data, t) {
    let formattedData = structureData(data)
    
    const plot1Options = {
        title: {
            text: t("statistics-plot1-title"),
            subtext: t("statistics-plot1-subtitle"),
            textStyle: {
                fontFamily: "Saira",
                fontSize: 16
            },
            subtextStyle: {
                fontFamily: "Saira",
                fontSize: 15
            },
            textAlign: "center",
            left: "middle"
        },
        tooltip: {
            trigger: 'item',
            formatter: (obj) => {
                return "<table>" + 
                "<tr><td>" + t("statistics-patient") + ": </td>" +
                "<td align = 'right'>" + obj.data[7] + "</td></tr>" +
                "<tr><td>" + t("statistics-pm-mean") + ": </td>" +
                "<td align = 'right'>" + ((obj.data[3] == null) ? "No Data" : obj.value[3].toFixed(2)) + "</td></tr>" +
                "<tr><td>" + t("statistics-day-mean") + ": </td>" +
                "<td align = 'right'>" + ((obj.data[2] == null) ? "No Data" : obj.value[2].toFixed(2)) + "</td></tr>" +
                "<tr><td>" + t("statistics-am-mean") + ": </td>" +
                "<td align = 'right'>" + ((obj.data[4] == null) ? "No Data" : obj.value[4].toFixed(2)) + "</td></tr>" +
                "<tr><td>" + t("statistics-gender") + ": </td>" +
                "<td align = 'right'>" + t(obj.data[5]) + "</td></tr>" +
                "<tr><td>" + t("statistics-hemogluco-count") + ": </td>" +
                "<td align = 'right'>" + obj.data[6] + "</td></tr>" +
                "<tr><td>" + t("statistics-age") + ": </td>" +
                "<td align = 'right'>" + obj.data[1] + "</td></tr>" +
                "</table>"
            }
        },
        xAxis: {
            type: 'value',
            min: function (value) {
                return value.min - 2;
            },
            max: function (value) {
                return value.max + 2;
            },
            name: t("statistics-age"),
            nameLocation: "middle",
            nameGap: 22
        },
        yAxis: {
            type: 'value',
            min: function (value) {
                return parseInt(value.min - 20);
            },
            max: function (value) {
                return parseInt(value.max + 20);
            },
            name: t("statistics-day-mean"),
            nameLocation: "middle",
            nameGap: 40
        },
        dataset: [
            {
                source: formattedData,
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "gender",
                        value: "male"
                    }
                }
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "gender",
                        value: "female"
                    }
                }
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "gender",
                        value: "other"
                    }
                }
            }
        ],
        legend: {
            top: "bottom"
        },
        series: [
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-mean",
                },
                itemStyle: {
                    color: genderColor["male"]
                },
                name: t("male"),
                datasetIndex: 1
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-mean",
                },
                itemStyle: {
                    color: genderColor["female"]
                },
                name: t("female"),
                datasetIndex: 2
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-mean",
                },
                itemStyle: {
                    color: genderColor["other"]
                },
                name: t("other"),
                datasetIndex: 3
            },
        ]
    }

    return plot1Options;
};

function plot2Create(data, t) {
    const formattedData = structureData(data)
    const maxValues = [130, 180]
    
    const legendList = [t("statistics-plot2-max-pm"), t("statistics-plot2-max-am")]
    let legendDict = {}
    legendList.forEach(item =>{
        legendDict[item] = false
    })

    const plot2Options = {
        title: {
            text: t("statistics-plot2-title"),
            subtext: t("statistics-plot2-subtitle"),
            textStyle: {
                fontFamily: "Saira",
                fontSize: 16
            },
            subtextStyle: {
                fontFamily: "Saira",
                fontSize: 15
            },
            textAlign: "center",
            left: "middle"
        },
        grid: [
            {bottom: '55%'},
            {top: '55%'}
        ],
        tooltip: {
            trigger: 'item',
            formatter: (obj) => {
                return "<table>" + 
                "<tr><td>" + t("statistics-patient") + ": </td>" +
                "<td align = 'right'>" + obj.data[7] + "</td></tr>" +
                "<tr><td>" + t("statistics-pm-mean") + ": </td>" +
                "<td align = 'right'>" + ((obj.data[3] == null) ? "No Data" : obj.value[3].toFixed(2)) + "</td></tr>" +
                "<tr><td>" + t("statistics-day-mean") + ": </td>" +
                "<td align = 'right'>" + ((obj.data[2] == null) ? "No Data" : obj.value[2].toFixed(2)) + "</td></tr>" +
                "<tr><td>" + t("statistics-am-mean") + ": </td>" +
                "<td align = 'right'>" + ((obj.data[4] == null) ? "No Data" : obj.value[4].toFixed(2)) + "</td></tr>" +
                "<tr><td>" + t("statistics-gender") + ": </td>" +
                "<td align = 'right'>" + t(obj.data[5]) + "</td></tr>" +
                "<tr><td>" + t("statistics-hemogluco-count") + ": </td>" +
                "<td align = 'right'>" + obj.data[6] + "</td></tr>" +
                "<tr><td>" + t("statistics-age") + ": </td>" +
                "<td align = 'right'>" + obj.data[1] + "</td></tr>" +
                "</table>"
            }
        },
        xAxis: [
            {
                type: 'value',
                min: function (value) {
                    return value.min - 2;
                },
                max: function (value) {
                    return value.max + 2;
                },
                name: t("statistics-age"),
                nameLocation: "middle",
                nameGap: 22,
                gridIndex: 0
            },
            {
                type: 'value',
                min: function (value) {
                    return value.min - 2;
                },
                max: function (value) {
                    return value.max + 2;
                },
                name: t("statistics-age"),
                nameLocation: "middle",
                nameGap: 22,
                gridIndex: 1
            }
        ],
        yAxis: [
            {
                type: 'value',
                min: function (value) {
                    return parseInt(value.min - 20);
                },
                max: function (value) {
                    return parseInt(value.max + 20);
                },
                name: t("statistics-pm-values"),
                nameLocation: "middle",
                nameGap: 40,
                gridIndex: 0
            },
            {
                type: 'value',
                min: function (value) {
                    return parseInt(value.min - 20);
                },
                max: function (value) {
                    return parseInt(value.max + 20);
                },
                name: t("statistics-am-values"),
                nameLocation: "middle",
                nameGap: 40,
                gridIndex: 1
            }
        ],
        dataset: [
            {
                source: formattedData,
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "gender",
                        value: "male"
                    }
                }
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "gender",
                        value: "female"
                    }
                }
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "gender",
                        value: "other"
                    }
                }
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "hemogluco-pm",
                        ">": maxValues[1]
                    }
                }
            },
            {
                transform: {
                    type: "filter",
                    config: {
                        dimension: "hemogluco-am",
                        ">": maxValues[0]
                    }
                }
            }
        ],
        legend: {
            top: "bottom",
            itemWidth: 12,
            itemHeight: 12,
            selected: legendDict
        },
        series: [
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-pm",
                },
                // markLine: {
                //     data: [
                //         {
                //             type: 'average', 
                //             name: 'Mean PM Male'
                //         }
                //     ],
                //     silent: true,
                //     symbol: ["circle", "none"],
                //     lineStyle: {
                //         opacity: 0.5
                //     }
                // },
                itemStyle: {
                    color: genderColor["male"],
                },
                symbol: "triangle",
                name: t("male"),
                datasetIndex: 1
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-pm",
                },
                // markLine: {
                //     data: [
                //         {
                //             type: 'average', 
                //             name: 'Mean PM Female'
                //         }
                //     ],
                //     silent: true,
                //     symbol: ["circle", "none"],
                //     lineStyle: {
                //         opacity: 0.5
                //     }
                // },
                itemStyle: {
                    color: genderColor["female"],
                },
                symbol: "triangle",
                name: t("female"),
                datasetIndex: 2
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-pm",
                },
                // markLine: {
                //     data: [
                //         {
                //             type: 'average', 
                //             name: 'Mean PM Other'
                //         }
                //     ],
                //     silent: true,
                //     symbol: ["circle", "none"],
                //     lineStyle: {
                //         opacity: 0.5
                //     }
                // },
                itemStyle: {
                    color: genderColor["other"],
                },
                symbol: "triangle",
                name: t("other"),
                datasetIndex: 3
            },
    
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-am",
                },
                // markLine: {
                //     data: [
                //         {
                //             type: 'average', 
                //             name: 'Mean AM Male'
                //         }
                //     ],
                //     silent: true,
                //     symbol: ["circle", "none"],
                //     lineStyle: {
                //         opacity: 0.5
                //     }
                // },
                itemStyle: {
                    color: genderColor["male"],
                },
                symbol: "triangle",
                symbolRotate: 180,
                name: t("male"),
                datasetIndex: 1,
                xAxisIndex: 1,
                yAxisIndex: 1
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-am",
                },
                // markLine: {
                //     data: [
                //         {
                //             type: 'average', 
                //             name: 'Mean AM Female'
                //         }
                //     ],
                //     silent: true,
                //     symbol: ["circle", "none"],
                //     lineStyle: {
                //         opacity: 0.5
                //     }
                // },
                itemStyle: {
                    color: genderColor["female"],
                },
                symbol: "triangle",
                symbolRotate: 180,
                name: t("female"),
                datasetIndex: 2,
                xAxisIndex: 1,
                yAxisIndex: 1
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-am",
                },
                // markLine: {
                //     data: [
                //         {
                //             type: 'average', 
                //             name: 'Mean AM Female'
                //         }
                //     ],
                //     silent: true,
                //     symbol: ["circle", "none"],
                //     lineStyle: {
                //         opacity: 0.5
                //     }
                // },
                itemStyle: {
                    color: genderColor["other"],
                },
                symbol: "triangle",
                symbolRotate: 180,
                name: t("other"),
                datasetIndex: 3,
                xAxisIndex: 1,
                yAxisIndex: 1
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-pm",
                },
                markLine: {
                    data: [
                        {
                            yAxis: maxValues[1]
                        }
                    ],
                    silent: true,
                    symbol: ["none", "none"],
                    lineStyle: {
                        opacity: 0.5
                    }
                },
                itemStyle: {
                    color: "red",
                },
                symbol: "triangle",
                name: t("statistics-plot2-max-pm"),
                datasetIndex: 4
            },
            {
                type: "scatter",
                encode: {
                    x: "age",
                    y: "hemogluco-am",
                },
                markLine: {
                    data: [
                        {
                            yAxis: maxValues[0]
                        }
                    ],
                    silent: true,
                    symbol: ["none", "none"],
                    lineStyle: {
                        opacity: 0.5
                    }
                },
                itemStyle: {
                    color: "red"
                },
                symbol: "triangle",
                symbolRotate: 180,
                name: t("statistics-plot2-max-am"),
                datasetIndex: 5,
                xAxisIndex: 1,
                yAxisIndex: 1
            },
        ]
    };

    return plot2Options;
};

////////////////////////////////////////////////////////////////////

const BorderLinearProgress = withStyles((theme) => ({
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        backgroundColor: '#e0e0e0',
    },
}))(LinearProgress);

const Statistics = ({user, users, organizations, practitioners, statisticsPlotData, dispatch}) => {
    const classes = useStyles();
    const dialogRef = useRef();
    const [tab, setTab] = React.useState(0);
    const {t, i18n} = useTranslation();
    let { id } = useParams();

    useEffect(() => {
        if(!users) {
            loadUsers(user, dispatch);
        }

        if(!organizations) {
            loadOrganizations(user, dispatch);
        }
        
        if(!practitioners) {
            loadPractitioners(user, dispatch);
        }

        if(!statisticsPlotData) {
            loadStatisticsData(user, dispatch)
        }

    }, [user]);
    
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleChangeIndex = (index) => {
        setTab(index);
    };

    const currentUser = user && users? users.find(u => u.username === user.username): null;
    const currentData = statisticsPlotData ? statisticsPlotData: null;

    return (
        <React.Fragment>
            <div>
                <Grid item xs={'auto'} className={classes.verticalAlignBlock}>
                    <Grid container direction="row" className={classes.spaceBetween}>
                        <Grid item xs={'auto'}>
                            <Typography>
                                {t('user-area')}: {currentUser ? t(currentUser.practitioner) : t('user-not-defined')}
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography>
                                {t('user-medical-center')}: {currentUser ? (currentUser.organization) : t('user-not-defined')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.spaceBetween}>
                        <Grid item xs={'auto'}>
                            <Typography variant="h5" gutterBottom>
                                <b>{currentUser ? (currentUser.name || currentUser.username) : t('user-no-name')}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h7" gutterBottom>
                                {moment().locale('es').format('llll')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Tabs value={tab} onChange={handleChange}
                      indicatorColor="primary" textColor="primary" variant="fullWidth"
                      aria-label="full width tabs example">
                    {tabs.map((tab, index) => <Tab label={t(tab.name)} icon={tab.icon} {...a11yProps(index)}/>)}
                </Tabs>

                <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={tab} index={0}>
                        {(!currentData) ?
                            <>
                                <Skeleton animation="wave" height={800} style={{marginTop: -150}}/>
                            </> :
                        (Object.keys(currentData).length===0) ?
                            <> NoData </>: 
                            <ReactECharts
                            option={plot1Create(currentData, t)}
                            style={{marginTop: 20, height: 600}}
                            />
                        }
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                    {(!currentData) ?
                            <>
                                <Skeleton animation="wave" height={800} style={{marginTop: -150}}/>
                            </> :
                        (Object.keys(currentData).length===0) ?
                            <> NoData </>: 
                            <ReactECharts
                            option={plot2Create(currentData, t)}
                            style={{marginTop: 20, height: 600}}
                            />
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
        </React.Fragment>
    );
}

export default connect(getData)(Statistics)
