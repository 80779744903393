const patientEncounterDetails = (state = {appointment: null}, action) => {
    switch (action.type) {
        case 'UPDATE_PATIENT_ENCOUNTER_DETAILS':
            return {
                ...state,
                appointment: action.appointment
            };
        default:
            return state
    }
};

export default patientEncounterDetails;