import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AccountNetwork,
  AccountSettings,
  BellOutline,
  Calendar,
  ChartLine,
  CloudSearchOutline,
  Home,
  HomeOutline,
  HospitalBuilding
} from 'mdi-material-ui';


import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import Patients from "../screens/Patients";
import Organizations from "../screens/Organizations";
import PatientAppointments from "../screens/PatientAppointments";
import AppointmentForm from "../screens/appointment_form/AppointmentForm";
import Login from "../screens/Login";
import LogoutButton from "./LogoutButton";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import {Auth} from "aws-amplify";
import {connect} from "react-redux";
import {login} from "../store/actions";
import AppointmentDetails from "../screens/AppointmentDetails";
import NavigationItem from "./NavigationItem";
import NotFound from "../screens/NotFound";
import ForgotPassword from "../screens/ForgotPassword";
import Users from "../screens/Users";
import ChangePassword from "../screens/ChangePassword";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import PatientExams from "../screens/PatientExams";
import MedicalRecord from "../screens/MedicalRecord";
import SearchPatient from "../screens/SearchPatient";
import Profile from "../screens/Profile";
import Statistics from "../screens/Statistics";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    "min-height": '100vh'
  },
  backgroundStyled: {
    background: 'linear-gradient(235deg, rgba(9,250,227,1) 0%, rgba(9,153,250,1) 30%, rgba(2,24,66,1) 100%)',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    height: 'auto',
    "borderRadius": "0px 0px 15px 0px"
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    maxWidth: '100%',
    paddingTop: 10,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  container: {
   'padding-left': 10,
   'padding-right': 10,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  maxHeight: {
    height: "100%"
  },
  positionBottom: {
    position: "absolute",
    bottom: "10px",
    width: "100%"
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  blackText: {
    color: 'black'
  },
  titleVoyager: {
    'font-size': '35px',
    'font-weight': 'bold'
  },
  imageIcon: {
    height: '100%',
    width: '100%',
    'filter': 'contrast(0) brightness(0)'
  },
  iconRoot: {
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
    marginLeft: theme.spacing(1),
    textAlign: 'center'
  },
  justifyCenterVertical: {
    display: 'flex',
    'justify-content': 'flex-start',
    'align-items': 'center',
  },
  justifyCenterVerticalFlexEnd: {
    display: 'flex',
    'justify-content': 'flex-end',
    'align-items': 'center',
  },
  verticalLine: {
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
    'backgroundColor': '#021842',
    marginLeft: theme.spacing(1),
    width: '3px',
    height: '30px',
    float: 'left'
  },
  toolbarButton: {
    backgroundColor: '#E6ECF2',
    marginLeft: theme.spacing(1),
  },
  toolbarIconColor: {
    color: '#0C2975'
  }
}));

const routes = [
  {
    path: "/",
    exact: true,
    appBar: false,
    sidebar: () => <Login/>,
  },
  {
    path: "/forgot-password",
    appBar: false,
    sidebar: () => <ForgotPassword/>,
  },
  {
    path: "/login",
    appBar: false,
    sidebar: () => <Login/>,
  },
  {
    path: "/change-password",
    appBar: false,
    sidebar: () => <ChangePassword/>,
  },
  {
    path: "/patients",
    appBar: true,
    sidebar: () => <Patients/>,
  },
  {
    path: "/appointments/:id",
    appBar: true,
    sidebar: () => <PatientAppointments/>,
  },
  {
    path: "/patient-exams/:id",
    appBar: true,
    sidebar: () => <PatientExams/>,
  },
  {
    path: "/organizations",
    appBar: true,
    sidebar: () => <Organizations/>,
  },
  {
    path: "/users",
    appBar: true,
    sidebar: () => <Users/>,
  },
  {
    path: "/search-patient",
    appBar: true,
    sidebar: () => <SearchPatient/>,
  },
  {
    path: "/profile",
    appBar: true,
    sidebar: () => <Profile/>,
  },
  {
    path: "/statistics",
    appBar: true,
    sidebar: () => <Statistics/>,
  },
  {
    path: "/appointment-form/:id",
    appBar: true,
    sidebar: () => <AppointmentForm/>,
  },
  {
    path: "/medical-record/:id",
    appBar: true,
    sidebar: () => <MedicalRecord/>,
  },
  {
    path: "/appointment-details/:id",
    appBar: true,
    sidebar: () => <AppointmentDetails/>,
  },
  {
    path: "/*",
    appBar: true,
    sidebar: () => <NotFound/>,
  }
];

const getUser = state => ({
  user: state.user.user
})

const Navigation = ({user, dispatch}) => {
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const navigationItems = [
    {
      icon: <Home/>,
      primary: t('home'),
      to: "/patients",
      restrictedTo: ['all'],
      disabled: false,
    },
    {
      icon: <HospitalBuilding/>,
      primary: t('organizations'),
      to: "/organizations",
      restrictedTo: ['admin'],
      disabled: false,
    },
    {
      icon: <AccountNetwork/>,
      primary: t('users'),
      to: "/users",
      restrictedTo: ['hospitalAdmin'],
      disabled: false,
    },
    {
      icon: <CloudSearchOutline/>,
      primary: t('advanced-search'),
      to: "/search-patient",
      restrictedTo: ['all'],
      disabled: false,
    },
    {
      icon: <Calendar/>,
      primary: t('calendar'),
      to: "/calendar",
      restrictedTo: ['all'],
      disabled: true,
    },
    {
      icon: <ChartLine/>,
      primary: t('statistics'),
      to: "/statistics",
      restrictedTo: ['all'],
      disabled: false,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    if (!user) {
      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        dispatch(login(user));
      }).catch(err => {
        // console.log(err);
      });
    }
  }, []);

  const role = (user && user.signInUserSession && user.signInUserSession.idToken
    && user.signInUserSession.idToken.payload && user.signInUserSession.idToken.payload['custom:role'])? user.signInUserSession.idToken.payload['custom:role'] : 'practitioner';

  return (
    <Router>
      <Switch>
        {routes.map((route, index) => (
          // You can render a <Route> in as many places
          // as you want in your app. It will render along
          // with any other <Route>s that also match the URL.
          // So, a sidebar or breadcrumbs or anything else
          // that requires you to render multiple things
          // in multiple places at the same URL is nothing
          // more than multiple <Route>s.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={
              <div className={clsx(classes.root, {[classes.backgroundStyled] : !route.appBar})}>

                {route.appBar? <CssBaseline/> : null}
                {route.appBar? <AppBar elevation={2} position="fixed" style={{"borderRadius": "0px 0px 20px 0px", "backgroundColor": "#FFFFFF"}}
                                       className={clsx(classes.appBar)}>
                  <Toolbar style={{"backgroundColor": "#FFFFFF",
                    "borderRadius": "0px 0px 20px 0px"}}>
                    <IconButton edge="start" color="primary"
                      aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton)}>
                      <MenuIcon/>
                    </IconButton>
                    <Grid container justifyContent="center" className={classes.justifyCenterVertical}>
                      <Typography component="h1" variant="h5" className={clsx(classes.titleVoyager, classes.blackText)}>
                        VOYAGER
                      </Typography>
                      <div className={classes.verticalLine}>
                      </div>
                      <Icon fontSize="large" classes={{root: classes.iconRoot}}>
                        <img className={classes.imageIcon} src="/logo-unit.svg"/>
                      </Icon>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.justifyCenterVerticalFlexEnd}>
                      <Link style={{textDecoration: 'none', color: 'black'}} to={'/patients'}>
                        <IconButton size="small" className={classes.toolbarButton}>
                          <HomeOutline className={classes.toolbarIconColor}/>
                        </IconButton>
                      </Link>
                      <IconButton size="small" className={classes.toolbarButton}>
                        <BellOutline className={classes.toolbarIconColor}/>
                      </IconButton>
                      <Link style={{textDecoration: 'none', color: 'black'}} to={'/profile'}>
                        <IconButton size="small" className={classes.toolbarButton}>
                          <AccountSettings className={classes.toolbarIconColor}/>
                        </IconButton>
                      </Link>
                    </Grid>


                  </Toolbar>
                </AppBar> : null}
                {route.appBar? <Drawer
                  anchor="left"
                  variant="temporary"
                  open={open}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  onClose={handleDrawerOpen}
                >
                  {/*<div className={classes.drawerHeader}>*/}
                  {/*  <IconButton onClick={handleDrawerOpen}>*/}
                  {/*    <ChevronLeftIcon />*/}
                  {/*  </IconButton>*/}
                  {/*</div>*/}
                  <Divider />
                  <List>
                    <div>
                      {user? navigationItems.map((item, index) => (
                        item.restrictedTo.some(r => r === role || r === 'all')? <NavigationItem key={index} icon={item.icon} primary={item.primary} to={item.to} disabled={item.disabled} /> : null
                      ))  : <CircularProgress className={classes.marginCircular}/>
                      }
                      <div style={{"marginBottom": "10px"}}>
                        <Divider/>
                        <LogoutButton/>
                      </div>
                    </div>
                  </List>
                </Drawer> : null}
                <main className={clsx(classes.content)}>
                  {route.appBar? <div className={classes.appBarSpacer}/> : null}

                  <Grid className={classes.container}>
                    <route.sidebar/>
                  </Grid>
                </main>

              </div>}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default connect(getUser)(Navigation)