const patientExams = (state = {exams: null, types: null}, action) => {
    switch (action.type) {
        case 'UPDATE_PATIENT_EXAMS':
            return {
                ...state,
                exams: action.exams
            };
        case 'UPDATE_EXAM_TYPES':
            return {
                ...state,
                types: action.types
            };
        default:
            return state
    }
};

export default patientExams;