import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import 'react-h5-audio-player/lib/styles.css';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PatientHistory from "./PatientHistory";
import AppointmentTopic from "./AppointmentTopic";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useHistory, useParams} from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {loadAppointmentFromJSON, previousStep} from "../../store/actions";
import CurrentHealthRecord from "./CurrentHealthRecord";
import PhysicalExploration from "./PhysicalExploration";
import Procedures from "./Procedures";
import Diagnosis from "./Diagnosis";
import Treatment from "./Treatment";
import AppointmentSummary from "./AppointmentSummary";
import Button from "@material-ui/core/Button";
import InputFiles from 'react-input-files';
import {AccountTieOutline, AccountOutline, CalendarRange} from "mdi-material-ui";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/core/Alert";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: 'flex'
  },
  marginTop: {
    marginTop: 15
  },
  displayHidden: {
    display: 'none'
  },
  centerVertically: {
    display: 'flex',
    'align-items': 'center',
    margin: 'auto',
    'justify-content': 'center'
  },
  marginCircular: {
    margin: 10
  },
  paper: {
    padding: '6px 16px',
  },
  marginBottom: {
    marginBottom: 5
  },
  verticalAlign: {
    display: 'flex',
    margin: 'auto'
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  noPadding: {
    padding: 0
  },
  noRightPadding: {
    'padding-right': 0
  },
  button: {
    border: '1px solid rgba(255, 255, 255, 1)',
    color: 'white',
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  rightAlign: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  displayContent: {
    display: 'contents'
  },
  spanButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  "MuiButton-outlinedPrimary:hover": {
    /* border: 1px solid #333333; */
    'background-color': 'rgba(51, 51, 51, 0.04)',
  },
  backButton: {
    'margin-right': 10
  },
  iconTransparent: {
    color: "transparent",
    marginRight: '10px'
  },
  fontValueSize: {
    fontSize: '18px'
  },
  iconTitleData: {
    color: '#021842',
    marginRight: '10px'
  },
  fontTitleData: {
    fontSize: '16px',
    color: '#021842',
    fontWeight: 600
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));


const getStep = state => ({
  appointments: state.patientAppointments.appointments,
  step: state.appointmentStep.step,
  appointmentObj: state.appointmentStep.appointment
})

const renderSteps = (step, appointment, id) => {
  switch(step) {
    case 0:
      return <PatientHistory appointment={appointment}/>;
    case 1:
      return <AppointmentTopic appointment={appointment}/>;
    case 2:
      return <CurrentHealthRecord appointment={appointment}/>;
    case 3:
      return <PhysicalExploration appointment={appointment}/>;
    case 4:
      return <Procedures appointment={appointment}/>;
    case 5:
      return <Diagnosis appointment={appointment}/>;
    case 6:
      return <Treatment appointment={appointment}/>;
    case 7:
      return <AppointmentSummary appointment={appointment} patient={id}/>;
    default:
      return <PatientHistory/>;
  }
}

const AppointmentForm = ({step, appointments, appointmentObj, dispatch}) => {
  let { id } = useParams();
  let history = useHistory();
  const {t, i18n} = useTranslation();

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  if(!appointments) {
    history.push('/patients');
  }

  const exportToJson = () => {
    let filename = "data.json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      let blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(appointmentObj)))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(appointmentObj));
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const loadAppointment = (files) => {
    let fr = new FileReader();
    fr.onload = function(e) {
      dispatch(loadAppointmentFromJSON(JSON.parse(e.target.result)));
    };
    fr.readAsText(files[0]);
  }


  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  const currentAppointment = appointments? appointments.find(a => a.id === id) : {};

  const classes = useStyles();
  return (
    <React.Fragment>
      <div style={{boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'}}>
        <AppBar style={{"backgroundColor": "#F1F8FF", paddingBottom: '2px'}} position="static">
          <Toolbar style={{"backgroundColor": "#FFF", "borderRadius": "0px 0px 20px 20px", boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'}}>
            <Grid container direction="row" style={{'display': 'flex', 'justifyContent': 'space-between', alignItems: 'center'}}>
              <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-start'}}>
                <IconButton size="small" color="primary" className={useStyles().backButton} onClick={() => {
                  if (step === 0) {
                    history.go(-1)
                  } else {
                    dispatch(previousStep());
                  }
                }}>
                  <ChevronLeftIcon/>
                </IconButton>
              </Grid>
              <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography component="h2" style={{fontSize: '18px'}} variant="h6" color="primary" className={useStyles().white}>
                  {t('new-attention')}: <b>{currentAppointment.practitioner}</b>
                </Typography>
              </Grid>
              <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                {step < 7 ?
                    // <InputFiles accept="application/json" onChange={files => loadAppointment(files)}>
                    <label htmlFor="upload-photo">
                      <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          onChange={ (e) => loadAppointment(e.target.files) }
                      />
                      <Button variant="outlined" color="primary" component="span">
                        {t('load-file')}
                      </Button>
                    </label>
                  :
                    <Button variant="outlined" color="primary" onClick={() => exportToJson()}>
                      {t('download-appointment')}
                    </Button>
                }
              </Grid>

            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar elevation={0} style={{"backgroundColor": "#FFF"}} position="static">
          <Toolbar style={{"backgroundColor": "#F1F8FF", "paddingTop": '10px', "paddingBottom": "10px", "borderRadius": "0px 0px 20px 20px"}}>
            <Grid container direction="row" style={{'display': 'flex', 'justifyContent': 'space-between', alignItems: 'center'}}>
              <Grid item xs={4} className={classes.flexStart}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        <CalendarRange className={classes.iconTitleData}/>
                      </Grid>
                      <Grid item>
                        <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                          {t('modality')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        <CalendarRange className={classes.iconTransparent}/>
                      </Grid>
                      <Grid item>
                        <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                          {currentAppointment.appointmentName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={4} className={classes.flexStart}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        <AccountTieOutline className={classes.iconTitleData}/>
                      </Grid>
                      <Grid item>
                        <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                          {t('professional')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        <AccountTieOutline className={classes.iconTransparent}/>
                      </Grid>
                      <Grid item>
                        <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                          {currentAppointment.practitionerName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={4} className={classes.flexStart}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        <AccountOutline className={classes.iconTitleData}/>
                      </Grid>
                      <Grid item>
                        <Typography component="span" color="primary" className={useStyles().fontTitleData}>
                          {t('patient')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item>
                        <AccountOutline className={classes.iconTransparent}/>
                      </Grid>
                      <Grid item>
                        <Typography component="span" color="primary" className={useStyles().fontValueSize}>
                          {currentAppointment.patientName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

            </Grid>
            <Snackbar open={error} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={closeErrorSnackbar}>
              <Alert onClose={closeErrorSnackbar} color="info" severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </Toolbar>
        </AppBar>
        {renderSteps(step, currentAppointment, id)}
      </div>
    </React.Fragment>
  );
}

AppointmentForm.propTypes = {
  step: PropTypes.number
}


export default connect(getStep)(AppointmentForm)