import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import {connect} from 'react-redux';
import AppointmentStep from "../../components/appointment/AppointmentStep";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const PatientHistory = (props) => {
    const {t, i18n} = useTranslation();

    return (
        <React.Fragment>
            <div>
                <AppointmentStep profession={props.appointment.practitioner}
                                 practitionerName={props.appointment.practitionerName}
                                 step="patientHistory"
                                 stepName={t('background')}
                                 subStepName={t('patient-background')}
                                 stepNumber={t('step-1')}
                                 maxSteps={3}
                                 maxStepsAllForm={8}
                                 patientName={props.appointment.patientName}
                                 motive={props.appointment.appointmentName}
                                 activeStep={0}
                                 currentStep={0}/>
            </div>
        </React.Fragment>
  );
}

PatientHistory.propTypes = {
    appointment: PropTypes.object,
};

export default connect()(PatientHistory)