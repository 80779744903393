import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import {Check} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/core/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  marginTopStepper: {
    marginTop: theme.spacing(5),
  },
  stepperClass: {
    width: '100%'
  },
  textAreaTitle: {
    'margin-top': theme.spacing(3),
    'font-size': 13,
    'font-weight': 500,
    'text-align': 'left'
  },
  marginRight: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonClass: {
    'justify-content': 'flex-end',
    'margin-bottom': 'auto',
    'margin-top': 'auto',
    'align-items': 'center',
    'display': 'block'
  },
  justifySpaceBetween: {
    'display': 'flex',
    'justify-content': 'space-between'
  },
  subtitle: {
    'font-size': '14px'
  },
  formControl: {
    minWidth: "100%",
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#FB8F46',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#FB8F46',
    },
  },
  line: {
    borderColor: '#FEDAC2',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#FEDAC2',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#FB8F46',
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#FB8F46',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

export default function AppointmentFormStepper(props) {
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const [options, setOption] = React.useState({
    transcribeSelectedOption: 'Streaming',
  });


  const handleTranscribeValueChange = (prop) => (event, value) => {
    if(prop === 'transcribeSelectedOption') {
      props.onChangeTranscribeOption(value.name);
      setOption({ ...options, [prop]: value.name });
    } else {
      setOption({ ...options, [prop]: event.target.value });
    }

  };

  return (
    <React.Fragment>
      <div className={classes.marginTopStepper}>
        <Grid container spacing={3} className={classes.marginTopStepper}>
          <Stepper alternativeLabel connector={<QontoConnector />}
                   activeStep={props.activeStep}
                   className={classes.stepperClass}>
            {Array(props.maxSteps).fill({label: ''}).map((step, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={QontoStepIcon}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {!props.transcription ?
            <>
              <Grid container spacing={3} className={classes.justifySpaceBetween}>
                <Grid item xs={6}>
                  <Typography className={classes.textAreaTitle} gutterBottom>
                    {props.stepNumber} / {props.subStepName}
                  </Typography>
                </Grid>


                <Grid item xs={'auto'} className={classes.buttonClass}>

                </Grid>
              </Grid>
            </>
          : null}
      </div>
    </React.Fragment>
  );
}

AppointmentFormStepper.propTypes = {
  steps: PropTypes.array,
  stepName: PropTypes.string,
  stepNumber: PropTypes.string,
  subStepName: PropTypes.string,
  // uploadAudio: PropTypes.func,
  onChangeTranscribeOption: PropTypes.func,
  activeStep: PropTypes.number,
  maxSteps: PropTypes.number,
  patientName: PropTypes.string,
  motive: PropTypes.string,
  uploading: PropTypes.bool,
  transcription: PropTypes.bool
};

AppointmentFormStepper.defaultProps = {
  transcription: false,
};
