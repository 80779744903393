import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import CaptureAudioComponent from "../audio/CaptureAudioComponent";
import {Eye} from "mdi-material-ui";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  verticalAlign: {
    display: 'flex',
    margin: 'auto'
  },
  fabProgress: {
    position: 'absolute',
    color: '#0999FA',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  fabProgressIncomplete: {
    position: 'absolute',
    color: '#ddd',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  stepNameDiv: {
    width: 200,
    height: 200,
    'justify-content': 'center',
    'position': 'relative',
  },
  textAlign: {
    'text-align': 'center'
  },
  columnVerticalAlign: {
    'justify-content': 'center',
    'display': 'flex',
    'flex-direction': 'column',
    'height': '100%'
  },
  topicTitle: {
    'font-size': 20,
    'font-weight': 100,
    'text-align': 'center'
  },
}));


export default function AppointmentFormStepName(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={'auto'} className={classes.verticalAlign}>
            <div className={classes.stepNameDiv}>
              <div className={classes.columnVerticalAlign}>
                {!props.noAudio ?
                    <CaptureAudioComponent step={props.step} transcribing={props.transcribing}
                                           uploadAudio={props.uploadAudio}
                                           transcribeOption={props.transcribeOption}
                                           updateStreamingText={props.updateStreamingText}/> :
                    <Grid container direction="column" className={classes.wrapper}>
                      <Grid item style={{'marginBottom': 5}}>
                        <Eye color="secondary" fontSize="large"/>
                      </Grid>
                    </Grid>
                }
              </div>
              <CircularProgress size={210} thickness={1.5} variant="determinate" value={100}  className={classes.fabProgressIncomplete} />
              <CircularProgress size={210} thickness={1.5} variant="determinate" value={(props.currentStep + 1) / props.maxStepsAllForm * 100} className={classes.fabProgress} />
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

AppointmentFormStepName.propTypes = {
  activeStep: PropTypes.number,
  currentStep: PropTypes.number,
  maxSteps: PropTypes.number,
  step: PropTypes.string,
  transcribing: PropTypes.bool,
  uploadAudio: PropTypes.func,
  updateStreamingText: PropTypes.func,
  maxStepsAllForm: PropTypes.number,
  transcribeOption: PropTypes.string,
  noAudio: PropTypes.bool
};

AppointmentFormStepName.defaultProps = {
  noAudio: false,
};
